import React from "react";
import RideCard from "../../Component/RideCard";
import { useParams } from "react-router-dom";
import classes from "./CustomerDetails.module.css";
import moment from "moment";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import TableSkeleton from "../../Component/TableSkeleton";
import { BaseURL, apiHeader, recordsLimit } from "../../config/apiUrl";
import { useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import NoData from "../../Component/NoData/NoData";
import { Loader } from "../../Component/Loader";

const CustomerDetails = () => {
  const id = useParams()?.id;
  const [loading, setLoading] = useState("initial");
  const [apiData, setApiData] = useState(null);
  const { access_token: accessToken } = useSelector(
    (state) => state?.authReducer
  );
  const [widthdrawData, setWidthdrawData] = useState([]);
  const getData = async () => {
    setLoading("initial");
    const [response, response1] = await Promise.allSettled([
      Get(BaseURL(`users/detail/${id}`), accessToken),
      Get(BaseURL(`transactions/admin?id=${id}`), accessToken),
    ]);
    setLoading("");

    console.log(response);

    if (response?.status == "fulfilled") {
      setApiData(response?.value?.data?.data?.user);
    }
    if (response1?.status == "fulfilled") {
      setWidthdrawData(response1?.value?.data?.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const activeDeactivateApi = async () => {
    setLoading("active-deactive");
    const url = BaseURL(`users/active-or-inactive/${apiData?._id}`);
    const response = await Patch(
      url,
      {
        active: apiData?.active == "active" ? false : true,
      },
      apiHeader(accessToken)
    );
    setLoading("");

    if (response) {
      setApiData(response?.data?.data);
      toast.success(
        `Customer ${
          response?.data?.data?.active == "active" ? "Activated" : "Deactivated"
        } successfully`
      );
    }
  };

  return (
    <>
      <SideBarSkeleton>
        {loading == "initial" ? (
          <Loader className={classes?.loader} />
        ) : (
          <Container className={classes.main}>
            <Row className={"mt-5 "}>
              <div className={classes.rideCardWrap}>
                <h3>Customer Detail</h3>
              </div>
              <Col md={4}>
                <div className={classes.box_main}>
                  <div className={classes.circle}>
                    <h5>01</h5>
                  </div>
                  <div>
                    <p>Total Earnings</p>
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <div className={classes.box_main}>
                  <Row>
                    <Col md={6} className={classes.left_box}>
                      <div className={classes.circle}>
                        <h5>01</h5>
                      </div>
                      <div>
                        <p>Total Instant Rides</p>
                      </div>
                    </Col>
                    <Col md={6} className={classes.small_cricles}>
                      <div>
                        <div className={classes.circle}>
                          <h5>01</h5>
                        </div>
                        <div>
                          <p>Pending Rides</p>
                        </div>
                      </div>
                      <div>
                        <div className={classes.circle}>
                          <h5>01</h5>
                        </div>
                        <div>
                          <p>Completed Rides</p>
                        </div>
                      </div>
                      <div>
                        <div className={classes.circle}>
                          <h5>01</h5>
                        </div>
                        <div>
                          <p>Canceled Rides</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={4}>
                <div className={classes.box_main}>
                  <Row>
                    <Col md={6} className={classes.left_box}>
                      <div className={classes.circle}>
                        <h5>01</h5>
                      </div>
                      <div>
                        <p>Total Schedule Rides</p>
                      </div>
                    </Col>
                    <Col md={6} className={classes.small_cricles}>
                      <div>
                        <div className={classes.circle}>
                          <h5>01</h5>
                        </div>
                        <div>
                          <p>Pending Rides</p>
                        </div>
                      </div>
                      <div>
                        <div className={classes.circle}>
                          <h5>01</h5>
                        </div>
                        <div>
                          <p>Completed Rides</p>
                        </div>
                      </div>
                      <div>
                        <div className={classes.circle}>
                          <h5>01</h5>
                        </div>
                        <div>
                          <p>Canceled Rides</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className={"mt-5 "}>
              <div
                className={`${classes.rideCardWrap} ${classes.account_head}`}
              >
                <h3>Account Detail</h3>

                <Button
                  label={`${
                    apiData?.active == "active"
                      ? loading == "active-deactive"
                        ? "Deactivating..."
                        : "Deactivate Account"
                      : loading == "active-deactive"
                      ? "Activating..."
                      : "Activate Account"
                  } `}
                  variant="primary"
                  onClick={activeDeactivateApi}
                  disabled={loading == "active-deactive"}
                />
              </div>
              <div className={classes.account_details}>
                <div className={classes.account_pic_wrapper}>
                  <img
                    src={
                      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
                    }
                  />
                </div>
                <div className={classes.account_desc}>
                  <b>Name:</b>
                  <p>{apiData?.displayName}</p>
                </div>
                <div className={classes.account_desc}>
                  <b>Email:</b>
                  <p>{apiData?.email}</p>
                </div>
                <div className={classes.account_desc}>
                  <b>Phone Number:</b>
                  <p>{`${apiData?.dialCode}${apiData?.contact}`}</p>
                </div>
                <div className={classes.account_desc}>
                  <b>Address:</b>
                  <p>{`${apiData?.address}`}</p>
                </div>
                <div className={classes.account_desc}>
                  <b>Country:</b>
                  <p>{`${apiData?.country}`}</p>
                </div>
                <div className={classes.account_desc}>
                  <b>Province:</b>
                  <p>{`${apiData?.state}`}</p>
                </div>
                <div className={classes.account_desc}>
                  <b>City:</b>
                  <p>{`${apiData?.city}`}</p>
                </div>
                <div className={classes.account_desc}>
                  <b>Postal Code:</b>
                  <p>{`${apiData?.postalCode}`}</p>
                </div>
              </div>
            </Row>
        
            <Row className={"mt-5 "}>
              <div className={classes.rideCardWrap}>
                <h3>Routes</h3>
                <div className={classes.routes_box}>
                  {rideData?.map((item, index) => (
                    <RideCard item={item} key={index} />
                  ))}
                </div>
              </div>
            </Row>
            {/* <Row className={"mt-5 "}>
              <div className={classes.rideCardWrap}>
                <h3>Withdrawal Request</h3>
              </div>
              <div class="table100 ver1 m-b-110">
                <div class="table100-head">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th
                          class="cell100 column1"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          S.No
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Name
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Email
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Phone
                        </th>

                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Created At
                        </th>
                        <th
                          class="cell100 column4"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Status
                        </th>

                        <th class="cell100 column5" style={{ width: "25%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {loading ? (
                  <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                ) : (
                  <div class="table100-body js-pscroll ps ps--active-y">
                    <table>
                      <tbody>
                        {widthdrawData?.length > 0 ? (
                          widthdrawData?.map((item, index) => (
                            <tr class="row100 body">
                              <td
                                class="cell100 column1"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "10%",
                                  textAlign: "left",
                                }}
                              >
                                <div className={classes.descCol}>
                                  <p className="textOneLine">
                                    {item?.displayName}
                                  </p>
                                </div>
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "start",
                                }}
                              >
                                <p className="textOneLine">{item?.email}</p>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <p className="textOneLine">{item?.contact}</p>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {moment(item?.createdAt).format(
                                    "DD MMM YYYY hh:mm"
                                  )}
                                </div>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {item?.active == "system-deactivated"
                                  ? "Deactive"
                                  : "Active"}
                              </td>
                              <td
                                class="cell100 column5"
                                style={{ width: "25%" }}
                              >
                                <div className={classes.actions_btn}>
                                  <Button
                                    className={classes.viewDetailBtn}
                                    label={
                                      item?.active == "system-deactivated"
                                        ? "Activate"
                                        : "Deactivate"
                                    }
                                    onClick={() => {
                                      // setSelectedItem(item);
                                      // setShow("status-update");
                                      //   navigate(`/project-detail/${item?._id}`);
                                    }}
                                  />
                                  <Button
                                    className={classes.viewDetailBtn}
                                    label={"Edit"}
                                    onClick={() => {
                                      // setSelectedItem({ ...item, index });
                                      // setShow("add-edit");
                                      //   navigate(`/project-detail/${item?._id}`);
                                    }}
                                  />
                                  <Button
                                    className={classes.viewDetailBtn}
                                    label={"View"}
                                    onClick={() => {
                                      // setSelectedItem(item);
                                      // setShow("View");
                                      //   navigate(`/project-detail/${item?._id}`);
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoData text={"No Withdraw Requests Found"} />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Row> */}
          </Container>
        )}
      </SideBarSkeleton>
    </>
  );
};

export default CustomerDetails;
const rideData = [
  {
    status: "pending",
    pickUpAddress: "Lorem St Road",
    dropOffAddress: "Lorem St Road",
    createdAt: moment(),
    desc: "Louis David Bonneau-24479...",
  },
  {
    status: "pending",
    pickUpAddress: "Lorem St Road",
    dropOffAddress: "Lorem St Road",
    createdAt: moment(),
    desc: "Louis David Bonneau-24479...",
  },
  {
    status: "pending",
    pickUpAddress: "Lorem St Road",
    dropOffAddress: "Lorem St Road",
    createdAt: moment(),
    desc: "Louis David Bonneau-24479...",
  },
  {
    status: "approved",
    pickUpAddress: "Lorem St Road",
    dropOffAddress: "Lorem St Road",
    createdAt: moment(),
    desc: "Louis David Bonneau-24479...",
  },
  {
    status: "approved",
    pickUpAddress: "Lorem St Road",
    dropOffAddress: "Lorem St Road",
    createdAt: moment(),
    desc: "Louis David Bonneau-24479...",
  },
];
