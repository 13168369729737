import validator from "validator";

export const apiUrl = "https://emuvas-backend-dem-2af567691c89.herokuapp.com";

export const socketURL = `${apiUrl}`;
export const stripe_public_key = `pk_test_51L8FqNL51LXb45sopLMv0wOiEZvg9NhshZWh0vWC7Okl7Alm2n01MGG89jdvovL41Fw98xNs1I0VqIn29KCD9JQc00acfYgJBk`;

export const imageUrl = (url) => `${apiUrl}/api/v1/images/${url}`;
export const locationHelper = window.location.origin;

// Emuvas Api Key
export const googleMapApiKey = "AIzaSyD-louz9eakiRdPCnTeaHwOsInByoj07TM";

export const BaseURL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const CreateFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key])) {
      for (let d in data[key]) {
        if (typeof data[key][d] == "string") {
          formData.append(key, data[key][d]);
        } else if (
          data[key][d] instanceof File ||
          data[key][d] instanceof Date
        ) {
          formData.append(key, data[key][d]);
        } else {
          formData.append(
            data[key]?.length > 1 ? `${key}` : `${key}[]`,
            JSON.stringify(data[key][d])
          );
        }
      }
    } else if (typeof data[key] == "object") {
      if (data[key] instanceof File) {
        formData.append(key, data[key]);
      } else {
        formData.append(key, JSON.stringify(data[key]));
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export const firebaseVapidObject = {
  vapidKey:
    "BKlmZ2T6iSDR1mOAaQOzBEbwHdlYX71OqZOwvvja_oDliCf9eRrb8Rgo_dasRs9WCpgSppH0O0kvtIOc959bO0U",
};
export const capitalizeFirstLetter = (l) =>
  l.charAt(0).toUpperCase() + l.slice(1);

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";

export var recordsLimit = 10;
export var recordsLimitSM = 5;

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getFormattedPrice = (price) => {
  return `$${parseFloat(price).toFixed(2)}`;
};

export const getFormattedParams = (param) =>
  capitalizeFirstLetter(param?.replace(formRegEx, formRegExReplacer));

// isValidPhoneNumber
export const isValidPhoneNumber = (phNo) => {
  return (
    validator.isMobilePhone(phNo, "any", {
      strictMode: false,
    }) === true
  );
};

export const falsyArray = [
  null,
  undefined,
  "",
  0,
  false,
  "null",
  "undefined",
  "0",
  "false",
  NaN,
];

export const UploadImageValidtor = (file, videoAndImage) => {
  const validImageTypes = videoAndImage
    ? [
        // "image/gif",
        "image/jpeg",
        "image/png",
        "image/jpg",
        "image/webp",
        "video/mp4",
        ".mkv",
        ".avi", // .video file extention list
      ]
    : ["image/jpeg", "image/png", "image/jpg", "image/webp"];
  if (!validImageTypes.includes(file["type"])) {
    return false;
  }
  return true;
};
