import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Get, Patch, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { DropDown } from "../../Component/DropDown/DropDown";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SearchInput from "../../Component/SearchInput";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import TableSkeleton from "../../Component/TableSkeleton";
import useDebounce from "../../CustomHooks/useDebounce";
import { BaseURL, apiHeader, recordsLimit } from "../../config/apiUrl";
import AddEditPartnerModal from "../../modals/AddEditPartnerModal";
import classes from "./PartnerCrud.module.css";
import { HiLockClosed, HiLockOpen } from "react-icons/hi";
import AreYouSureModal from "../../modals/AreYouSureModal";

const PartnerCrud = () => {
  const { access_token: accessToken } = useSelector(
    (state) => state?.authReducer
  );
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalLoading, setIsModalLoading] = useState("");

  const [status, setStatus] = useState(statusoptions[0]);
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500);

  const [isLoading, setIsLoading] = useState(false);

  // getData
  async function getData(pg = page, sts = status?.value, searchText = search) {
    const url = BaseURL(
      `users/admin/all?userType=${"partner"}&status=${sts}&page=${pg}&limit=${recordsLimit}&search=${searchText}`
    );
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalRecords);
    }
    setLoading(false);
  }

  // handler
  async function handler(params) {
    // validation

    for (let key in params) {
      if (params[key] === "") {
        return toast.error(`${key} is required`);
      }
    }

    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }

    setIsLoading(true);
    const apiFunc = selectedItem ? Patch : Post;
    const response = await apiFunc(
      BaseURL(
        selectedItem
          ? `users/admin/update/user/${selectedItem?._id}`
          : "auth/admin/create/partner"
      ),
      formData,
      apiHeader(accessToken)
    );

    if (response) {
      const tempData = [...data];
      if (selectedItem) {
        tempData.splice(selectedItem?.index, 1, response?.data?.data);
      } else {
        tempData.unshift(response?.data?.data?.user);
      }
      setData(tempData);
      toast.success("Partner Added Successfully");
      setShow(false);
    }
    setSelectedItem(null);
    setIsLoading(false);
  }

  // handle status update
  const handleStatusUpdate = async () => {
    setIsModalLoading("status-update");
    const url = BaseURL(`users/active-or-inactive/${selectedItem?._id}`);
    const response = await Patch(
      url,
      {
        active: selectedItem?.active == "active" ? false : true,
      },
      apiHeader(accessToken)
    );
    setIsModalLoading("");
    if (response) {
      const newData = [...data];
      const index = newData.findIndex((item) => item._id == selectedItem._id);
      if (response?.data?.data?.active == status?.value) {
        newData.splice(index, 1, response?.data?.data);
      } else {
        newData.splice(index, 1);
      }
      setData(newData);
      setShow("");
      toast.success(
        `Partner ${
          response?.data?.data?.active == "active" ? "Activated" : "Deactivated"
        } successfully`
      );
    }
  };

  useEffect(() => {
    getData();
  }, [debounceSearch]);

  return (
    <SideBarSkeleton>
      <div className={classes.content}>
        <Container className={classes.main}>
          <Row className={classes.row}>
            <Col md={12}>
              <div className={classes.spBtw}>
                <h3>Partners</h3>
                <div className={classes?.searchAndFilters}>
                  <SearchInput setter={setSearch} value={search} />
                  <DropDown
                    customStyle={{ minWidth: "200px", padding: "2px 0px" }}
                    placeholder={"Status"}
                    variant="dashboard"
                    indicatorColor="var(--white-color)"
                    options={statusoptions}
                    value={status}
                    setter={(e) => {
                      setStatus(e);
                      setPage(1);
                      getData(1, e?.value);
                    }}
                  />
                  <Button
                    label={"Create"}
                    onClick={() => setShow("viewEditAdd")}
                  />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div class="table100 ver1 m-b-110">
                <div class="table100-head">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th
                          class="cell100 column1"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          S.No
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Name
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Email
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Company Name
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Contact No
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Package
                        </th>

                        <th
                          class="cell100 column3"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Created At
                        </th>
                        <th
                          class="cell100 column4"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Status
                        </th>

                        <th class="cell100 column5" style={{ width: "15%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {loading ? (
                  <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                ) : (
                  <div class="table100-body js-pscroll ps ps--active-y">
                    <table>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((item, index) => (
                            <tr class="row100 body">
                              <td
                                class="cell100 column1"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "10%",
                                  textAlign: "left",
                                }}
                              >
                                <div
                                  className={`${classes.descCol} `}
                                  title={item?.displayName}
                                >
                                  <span className="maxLine1 t-t-c">
                                    {item?.displayName}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="cell100 column2 "
                                style={{
                                  width: "15%",
                                  textAlign: "start",
                                }}
                                title={item?.email}
                              >
                                <span
                                  className="maxLine1"
                                  style={{
                                    textTransform: "lowercase",
                                  }}
                                >
                                  {item?.email}
                                </span>
                              </td>
                              <td
                                class="cell100 column2 "
                                style={{
                                  width: "10%",
                                  textAlign: "start",
                                }}
                                title={item?.companyName}
                              >
                                <span className="t-t-c maxLine1">
                                  {item?.companyName}
                                </span>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                <div
                                  className={`${classes.descCol} `}
                                  title={`${item?.dialCode}${item?.contact}`}
                                >
                                  <span className="maxLine1">
                                    {`${item?.dialCode}${item?.contact}`}
                                  </span>
                                </div>
                              </td>
                              <td
                                class="cell100 column4 "
                                style={{ width: "10%", textAlign: "left" }}
                                title={item?.packageDetails?.packageId?.name}
                              >
                                <span className="maxLine1 t-t-c">
                                  {item?.packageDetails?.packageId?.name}
                                </span>
                              </td>
                              <td
                                class="cell100 column4"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {moment(item?.createdAt).format("lll")}
                                </div>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{
                                  width: "10%",
                                  textAlign: "left",
                                }}
                              >
                                {item?.active === "system-deactivated"
                                  ? "Deactive"
                                  : "Active"}
                              </td>

                              <td
                                class="cell100 column5"
                                style={{ width: "15%" }}
                              >
                                <div className={classes.actions_btn}>
                                  <Button
                                    className={classes.viewDetailBtn}
                                    leftIcon={
                                      item?.active == "system-deactivated" ? (
                                        <HiLockOpen size={20} />
                                      ) : (
                                        <HiLockClosed size={20} />
                                      )
                                    }
                                    onClick={() => {
                                      setSelectedItem(item);
                                      setShow("status-update");
                                    }}
                                    title={
                                      item?.active == "system-deactivated"
                                        ? "Activate"
                                        : "Deactivate"
                                    }
                                  />

                                  <Button
                                    className={classes.viewDetailBtn}
                                    leftIcon={<AiFillEye size={20} />}
                                    onClick={() => {
                                      setSelectedItem({ ...item, index });
                                      navigate(`/partner-detail/${item?._id}`);
                                    }}
                                    title={"View"}
                                  />
                                  <Button
                                    className={classes.viewDetailBtn}
                                    leftIcon={<MdModeEditOutline size={20} />}
                                    onClick={() => {
                                      setSelectedItem({ ...item, index });
                                      setShow("viewEditAdd");
                                    }}
                                    title={"Edit"}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoData text={"No Partner Found"} />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className={classes.paginationDiv}>
                {!!data?.length && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e);
                    }}
                    currentPage={page}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {show === "status-update" && (
        <AreYouSureModal
          show={show}
          setShow={setShow}
          data={selectedItem}
          subTitle={`Are you sure you want to ${
            selectedItem?.active == "system-deactivated"
              ? "Activate"
              : "Deactivate"
          } this Partner?`}
          onClick={handleStatusUpdate}
          isApiCall={isModalLoading == "status-update"}
        />
      )}

      {show === "viewEditAdd" && (
        <AddEditPartnerModal
          show={show}
          setShow={() => {
            setShow(false);
            setSelectedItem(null);
          }}
          isLoading={isLoading}
          data={selectedItem}
          onClick={handler}
        />
      )}
    </SideBarSkeleton>
  );
};

export default PartnerCrud;

const statusoptions = [
  {
    value: "active",
    label: "Active",
  },
  {
    label: "Deactive",
    value: "system-deactivated",
  },
];
