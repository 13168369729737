import React, { useState } from "react";
import classes from "./SideBar.module.css";
import { BlueLogo } from "../../constant/imagePath";
import { SideBarMenu } from "../../config/DummyData";
import { FaHome, FaUserCircle, FaTasks, FaUserAlt } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { BsChatDots, BsChatSquareQuoteFill } from "react-icons/bs";
import { TbLogout } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../config/apiUrl";
import { io } from "socket.io-client";
import { signOutRequest } from "../../store/auth/authSlice";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { MdNotifications, MdTask } from "react-icons/md";
import { Button } from "../../Component/Button/Button";
import {
  clearChatCounter,
  clearNotiData,
} from "../../store/common/commonSlice";
import { HiUsers } from "react-icons/hi";
import { PiNotebookFill } from "react-icons/pi";
import {
  AiFillCar,
  AiFillDatabase,
  AiOutlinePrinter,
  AiTwotonePrinter,
} from "react-icons/ai";
import { BiSolidCategoryAlt } from "react-icons/bi";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activePath = useLocation().pathname;
  const { chat_counter, noti_counter } = useSelector(
    (state) => state?.commonReducer
  );
  const { user, fcmToken } = useSelector((state) => state?.authReducer);

  const RenderItem = ({ icon, title, path, subMenu = [] }) => {
    const navigate = useNavigate();
    const active = useLocation()?.pathname;
    const [subnav, setSubnav] = useState(false);
    const subActive = subMenu.find((item, index) => item?.path == active);
    const showSubnav = () => setSubnav(!subnav);
    const currentPath = SideBarMenu.find(
      (item) => item?.name == (path ?? title)
    )?.path;
    return (
      <>
        <div
          className={[
            classes?.listItemContainer,
            currentPath == active && classes?.active,
            subActive && classes?.subActive,
            subnav && classes?.marginZero,
          ].join(" ")}
          onClick={() => {
            if (subMenu?.length > 0) {
              showSubnav(!subnav);
            } else {
              navigate(currentPath);
            }
          }}
        >
          {icon}
          <span>{title}</span>
          {subMenu?.length > 0 &&
            (subnav ? (
              <RiArrowUpSFill
                size={20}
                color={"white"}
                className={classes?.dropDownIcon}
              />
            ) : (
              <RiArrowDownSFill
                size={20}
                color={"white"}
                className={classes?.dropDownIcon}
              />
            ))}
        </div>
        {subnav &&
          subMenu.map((item, index) => {
            return (
              <div
                className={[
                  classes?.innerItemContainer,
                  item?.path == active && classes?.active,
                ].join(" ")}
                key={index}
                onClick={() => {
                  navigate(item?.path);
                }}
              >
                {item?.icon}
                <span>{item.label}</span>
              </div>
            );
          })}
      </>
    );
  };

  const socket = useRef(null);

  const logout = () => {
    socket.current = io(apiUrl);
    socket.current.emit("logout", { _id: user?._id, fcmToken });
    dispatch(signOutRequest());
    navigate("/");
  };

  return (
    <div className={classes?.mainContainer}>
      <div className={classes?.logoContainer}>
        <h4
          style={{
            color: "var(--primary-color)",
          }}
        >
          E-Hailing Plaltform Admin
        </h4>
        {/* <img src={BlueLogo} onClick={() => navigate("/")} alt="..." /> */}
      </div>
      {/* <Button
        className={classes.btn}
        label="Transactions"
        onClick={() => navigate("/transactions")}
      /> */}
      <RenderItem icon={<FaHome size={22} />} title={"Dashboard"} />
      <RenderItem
        icon={<FaUserAlt size={20} />}
        title={`Users`}
        subMenu={[
          {
            path: "/drivers",
            label: "Drivers",
            icon: <FaUserAlt size={20} />,
          },
          {
            path: "/partners",
            label: "Partners",
            icon: <FaUserAlt size={20} />,
          },
          {
            path: "/customers",
            label: "Customers",
            icon: <FaUserAlt size={20} />,
          },
        ]}
        // path={"Notifications"}
      />{" "}
      <RenderItem
        icon={<PiNotebookFill size={20} />}
        title={`Requests`}
        subMenu={[
          {
            path: "/requested-drivers",
            label: "Requested Drivers",
            icon: <PiNotebookFill size={20} />,
          },
          {
            path: "/requested-partners",
            label: "Requested Partners",
            icon: <PiNotebookFill size={20} />,
          },
        ]}
        // path={"Notifications"}
      />
      <RenderItem
        icon={<AiFillDatabase size={20} />}
        title={`CRUDS`}
        subMenu={[
          // {
          //   path: "/type-crud",
          //   label: "Type Crud",
          //   icon: <BiSolidCategoryAlt size={20} />,
          // },
          // {
          //   path: "/vehicle-crud",
          //   label: "Vehicle Crud",
          //   icon: <AiFillCar size={20} />,
          // },
          {
            path: "/client-crud",
            label: "Client Crud",
            icon: <FaUserAlt size={20} />,
          },
        ]}
        // path={"Notifications"}
      />
      {/* <RenderItem icon={<FaHome size={22} />} title={"Type Crud"} />
      <RenderItem icon={<FaHome size={22} />} title={"Vehicle Crud"} />
      <RenderItem icon={<FaHome size={22} />} title={"Client Crud"} /> */}
      <RenderItem icon={<FaHome size={22} />} title={"Packages"} />
      <RenderItem
        icon={<AiTwotonePrinter size={22} />}
        title={"Withdrawal Requests"}
      />
      {/* <RenderItem icon={<GiCheckMark size={20} />} title={"Transactions"} /> */}
      {/* <RenderItem icon={<HiUsers size={20} />} title={"All Users"} /> */}
      {/* <RenderItem
        icon={<BsChatSquareQuoteFill size={20} />}
        title={'Awaiting Acceptance'}
      />
      <RenderItem
        icon={<FaTasks size={20} />}
        title={'New Tasks'}
      />
      <RenderItem
        icon={<MdTask size={20} />}
        title={'Archive'}
      /> */}
      {/* <RenderItem icon={<FiPackage size={20} />} title={"Packages"} /> */}
      {/* <div onClick={() => dispatch(clearChatCounter())}>
        <RenderItem
          icon={<BsChatDots size={20} />}
          title={`Chat ${chat_counter > 0 ? `(${chat_counter})` : ''}`}
          path={'Chat'}
        />
      </div> */}
      {/* <RenderItem icon={<FaUserCircle size={20} />} title={"My Profile"} /> */}
      {/* <div onClick={() => dispatch(clearNotiData())}>
        <RenderItem
          icon={<MdNotifications size={20} />}
          title={`Notifications ${noti_counter > 0 ? `(${noti_counter})` : ""}`}
          path={"Notifications"}
        />
      </div> */}
      <RenderItem
        icon={<IoMdSettings size={20} />}
        title={`Settings`}
        subMenu={[
          // {
          //   path: "/edit-packages",
          //   label: "Edit Packages",
          //   icon: <FiPackage size={20} />,
          // },
          {
            path: "/account-setting",
            label: "Account Setting",
            icon: <FaUserCircle size={20} />,
          },
          // {
          //   path: "/all-users",
          //   label: "All Users",
          //   icon: <HiUsers size={20} />,
          // },
        ]}
        // path={"Notifications"}
      />
      <div className={[classes?.listItemContainer].join(" ")} onClick={logout}>
        <TbLogout size={22} />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default SideBar;
