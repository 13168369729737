import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdCreate } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Delete, Get, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SearchInput from "../../Component/SearchInput";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import TableSkeleton from "../../Component/TableSkeleton";
import useDebounce from "../../CustomHooks/useDebounce";
import { BaseURL, apiHeader, recordsLimit } from "../../config/apiUrl";
import AddEditPartnerModal from "../../modals/AddEditPartnerModal";
import AreYouSureModal from "../../modals/AreYouSureModal";
import classes from "./RequestedPartners.module.css";

const RequestedPartners = () => {
  const { access_token: accessToken } = useSelector(
    (state) => state?.authReducer
  );

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [isModalLoading, setIsModalLoading] = useState("");
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500);

  // getData
  async function getData(pg = page, searchText = debounceSearch) {
    const url = BaseURL(
      `registration-request/admin/all?page=${pg}&limit=${recordsLimit}&userType=partner&search=${searchText}`
    );
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalRecords);
    }
    setLoading(false);
  }

  //  handle Reject Request
  const handleRejectRequest = async () => {
    setIsModalLoading("reject");
    const url = BaseURL(`registration-request/${selectedItem?._id}`);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsModalLoading("");
    if (response) {
      const newData = [...data];
      const index = newData.findIndex((item) => item._id == selectedItem._id);
      newData.splice(index, 1);
      setData(newData);
      setShow("");
      toast.success(`Partner rejected successfully`);
    }
  };

  useEffect(() => {
    setPage(1);
    getData(1);
  }, [debounceSearch]);

  return (
    <SideBarSkeleton>
      <div className={classes.content}>
        <Container className={classes.main}>
          <Row className={classes.row}>
            <Col md={12}>
              <div className={classes.spBtw}>
                <h3>Requested Partners</h3>
                <div className={classes?.searchAndFilters}>
                  <SearchInput setter={setSearch} value={search} />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div class="table100 ver1 m-b-110">
                <div class="table100-head">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th
                          class="cell100 column1"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          S.No
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Name
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Email
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Phone
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Company Address
                        </th>
                        <th
                          class="cell100 column4"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Company Name
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Created At
                        </th>

                        <th class="cell100 column5" style={{ width: "15%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {loading ? (
                  <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                ) : (
                  <div class="table100-body js-pscroll ps ps--active-y">
                    <table>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((item, index) => (
                            <tr class="row100 body">
                              <td
                                class="cell100 column1"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "10%",
                                  textAlign: "left",
                                }}
                              >
                                <div className={classes.descCol}>
                                  <p
                                    className="textOneLine t-t-c maxLine1"
                                    title={item?.displayName}
                                  >
                                    {item?.displayName}
                                  </p>
                                </div>
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "start",
                                }}
                              >
                                <p
                                  className="textOneLine"
                                  style={{
                                    textTransform: "lowercase",
                                  }}
                                  title={item?.email}
                                >
                                  {item?.email}
                                </p>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <p
                                  className="textOneLine maxLine1"
                                  title={item?.contact}
                                >
                                  {item?.contact}
                                </p>
                              </td>
                              <td
                                class="cell100 column4"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                <p
                                  className="textOneLine"
                                  title={item?.address}
                                >
                                  {item?.address}
                                </p>
                              </td>
                              <td
                                class="cell100 column4"
                                style={{ width: "10%", textAlign: "left" }}
                                title={item?.companyName}
                              >
                                <span className="t-t-c maxLine1">
                                  {item?.companyName}
                                </span>
                              </td>
                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {moment(item?.createdAt).format(
                                    "DD MMM YYYY hh:mm"
                                  )}
                                </div>
                              </td>

                              <td
                                class="cell100 column5"
                                style={{ width: "15%" }}
                              >
                                <div className={classes.actions_btn}>
                                  <Button
                                    className={classes.viewDetailBtn}
                                    title={"Create"}
                                    leftIcon={<MdCreate size={20} />}
                                    onClick={() => {
                                      setSelectedItem(item);
                                      setShow("create");
                                    }}
                                  />
                                  <Button
                                    className={classes.viewDetailBtn}
                                    title={"Reject"}
                                    leftIcon={<AiFillCloseCircle size={20} />}
                                    onClick={() => {
                                      setSelectedItem(item);
                                      setShow("reject");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoData text={"No Requested Partners Found"} />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className={classes.paginationDiv}>
                {!!data?.length && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e);
                    }}
                    currentPage={page}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {show === "reject" && (
        <AreYouSureModal
          show={show}
          setShow={setShow}
          data={selectedItem}
          subTitle={`Are you sure you want to Reject this Partner Request?`}
          onClick={handleRejectRequest}
          isApiCall={isModalLoading == "reject"}
        />
      )}
      {show === "create" && (
        <AddEditPartnerModal
          show={show}
          setShow={() => {
            setShow(false);
            setSelectedItem(null);
          }}
          isLoading={isLoading}
          data={selectedItem}
          requestMode={true}
          onClick={async (params) => {
            setIsLoading(true);

            const response = await Post(
              BaseURL("auth/admin/create/partner"),
              params,
              apiHeader(accessToken)
            );

            setIsLoading(false);

            if (response) {
              const newData = [...data];
              newData?.splice(
                newData?.findIndex((item) => item?._id == selectedItem?._id),
                1
              );
              setData(newData);
              setShow("");
              toast.success("Partner request accepted successfully.");
            }
          }}
        />
      )}
    </SideBarSkeleton>
  );
};

export default RequestedPartners;
