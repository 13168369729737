import React, { useEffect, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./ClientCrud.module.css";
import { useSelector } from "react-redux";
import { Delete, Get, Patch, Post } from "../../Axios/AxiosFunctions";
import {
  BaseURL,
  apiHeader,
  numberWithCommas,
  recordsLimit,
} from "../../config/apiUrl";
import PaginationComponent from "../../Component/PaginationComponent";
import NoData from "../../Component/NoData/NoData";
import TableSkeleton from "../../Component/TableSkeleton";
import { returnColoredStatus } from "../../constant/commonFunctions";
import { Button } from "../../Component/Button/Button";
import { useNavigate } from "react-router-dom";
import Tooltip from "../../Component/Tooltip";
import moment from "moment";
import AddEditTypeModal from "../../modals/AddEditTypeModal";
import AddEditVehicleModal from "../../modals/AddEditVehicleModal";
import { toast } from "react-toastify";
import ShowMoreShowLessText from "../../Component/ShowMoreShowLess/ShowMoreShowLessText";
import AddEditClientModal from "../../modals/AddEditClientModal";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";

const ClientCrud = () => {
  const { access_token: accessToken } = useSelector(
    (state) => state?.authReducer
  );
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [isModalLoading, setIsModalLoading] = useState("");
  async function getData(pg = page) {
    const url = BaseURL(
      `our-client-say/admin/all?page=${pg}&limit=${recordsLimit}`
    );
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalRecords);
    }
    setLoading(false);
  }
  async function HandleDelete() {
    setIsModalLoading(true);

    const response = await Delete(
      BaseURL(`our-client-say/${selectedItem?._id}`),
      null,
      apiHeader(accessToken)
    );
    if (response !== undefined) {
      const newData = [...data];
      newData?.splice(
        newData?.findIndex((item) => item?._id == response?.data?.data?._id),
        1
      );
      setData(newData);
      setIsModalOpen("");

      toast.success("Client deleted successfully");
    }
    setIsModalLoading(false);
  }

  useEffect(() => {
    getData(page);
  }, [page]);

  const addOrEditData = async (e) => {
    const url = BaseURL(
      selectedItem ? `our-client-say/${selectedItem?._id}` : "our-client-say"
    );
    const apiFunc = selectedItem ? Patch : Post;

    const response = await apiFunc(url, e, apiHeader(accessToken));
    if (response !== undefined) {
      const newData = [...data];
      if (selectedItem) {
        newData?.splice(
          newData?.findIndex((item) => item?._id == response?.data?._id),
          1,
          response?.data
        );
      } else {
        newData?.unshift(response?.data);
      }
      toast.success(
        `vehicle ${selectedItem ? "edited" : "created"} successfully`
      );
      setData(newData);
    }
    setShow("");
  };
  return (
    <SideBarSkeleton>
      <div className={classes.content}>
        <Container className={classes.main}>
          <Row className={classes.row}>
            <Col md={12}>
              <div className={classes.spBtw}>
                <h3>Our Clients</h3>

                <Button
                  label={"+ ADD Client"}
                  className={classes.DownloadReport}
                  customStyle={{
                    backgroundColor: "var(--clr-secondary)",
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "var(--ff-poppins-semibold)",
                    border: "none",
                  }}
                  onClick={() => {
                    setSelectedItem(null);
                    setShow("viewEditAdd");
                  }}
                />
              </div>
            </Col>
            <Col md={12}>
              <div class="table100 ver1 m-b-110">
                <div class="table100-head">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th
                          class="cell100 column1"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          S.No
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Name
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "30%", textAlign: "start" }}
                        >
                          Description
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Created At
                        </th>
                        <th
                          class="cell100 column4"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Active
                        </th>

                        <th class="cell100 column5" style={{ width: "20%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {loading ? (
                  <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                ) : (
                  <div class="table100-body js-pscroll ps ps--active-y">
                    <table>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((item, index) => (
                            <tr class="row100 body">
                              <td
                                class="cell100 column1"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "10%",
                                  textAlign: "left",
                                }}
                              >
                                <div className={classes.descCol}>
                                  {item?.name}
                                </div>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "30%", textAlign: "left" }}
                              >
                                <label className={classes?.p}>
                                  {item?.description}
                                </label>
                              </td>
                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {moment(item?.createdAt).format(
                                    "DD MMM YYYY hh:mm"
                                  )}
                                </div>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{
                                  width: "15%",
                                  textAlign: "left",
                                  // color: item?.isActive ? 'green' : 'red',
                                }}
                              >
                                {item?.isActive ? "Active" : "Deactive"}
                              </td>

                              <td
                                class="cell100 column5"
                                style={{ width: "20%" }}
                              >
                                <div className={classes.actions_btn}>
                                  <Button
                                    className={classes.viewDetailBtn}
                                    title={"Edit"}
                                    leftIcon={<MdModeEditOutline size={20} />}
                                    onClick={() => {
                                      setSelectedItem(item);
                                      setShow("viewEditAdd");
                                      //   navigate(`/project-detail/${item?._id}`);
                                    }}
                                  />
                                  <Button
                                    className={classes.deleteBtn}
                                    title={"Delete"}
                                    leftIcon={<AiFillDelete size={20}/>}
                                    onClick={() => {
                                      setSelectedItem(item);
                                      setIsModalOpen("delete");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoData text={"No Client Found"} />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className={classes.paginationDiv}>
                {!!data?.length && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={setPage}
                    currentPage={page}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {show === "viewEditAdd" && (
        <AddEditClientModal
          show={show}
          setShow={setShow}
          data={selectedItem}
          onClick={addOrEditData}
        />
      )}
      {isModalOpen == "delete" && (
        <AreYouSureModal
          show={isModalOpen == "delete"}
          setShow={() => setIsModalOpen("")}
          isApiCall={isModalLoading}
          onClick={HandleDelete}
          subTitle={"Do you want to delete this Client?"}
        />
      )}
    </SideBarSkeleton>
  );
};

export default ClientCrud;
