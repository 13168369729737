import moment from "moment/moment";
import React from "react";
import { BiRadioCircle } from "react-icons/bi";
import { IoLocationSharp } from "react-icons/io5";
import classes from "./RideCard.module.css";

const RideCard = ({ item, onClick }) => {
  return (
    <div className={`${classes.main}`} onClick={() => onClick && onClick()}>
      <div className={classes.headWrap}>
        <div>
          <span className={classes.delivery}>Delivery: </span>
          <span className={classes.desc}>{item?.orderDetail || "N/A"}</span>
        </div>
        <div className={classes.schedule}>{item?.status}</div>
      </div>
      <div className={classes.rideDetailsWrap}>
        <div className={classes.locationDetailWrap}>
          <div className={classes.rideDetails}>
            <div className={classes.icon1Wrap}>
              <BiRadioCircle size={25} />
            </div>
            <div>
              <div className={classes.locationHead}>Pick Up Location</div>
              <div className={classes.location}>{item?.pickUpAddress}</div>
            </div>
          </div>
          <div className={classes.rideDetails}>
            <div className={classes.icon2Wrap}>
              <IoLocationSharp color="#DB0000" size={25} />
            </div>
            <div>
              <div className={classes.locationHead}>Drop Off Location</div>
              <div className={classes.location}>
                {item?.rides?.at(-1)?.dropOffAddress}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.time}>
          {moment(item?.scheduledDate).format("lll")}
        </div>
      </div>
    </div>
  );
};

export default RideCard;
