import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Patch, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import {
  apiHeader,
  BaseURL,
  CreateFormData,
  imageUrl,
  validateEmail,
} from "../../config/apiUrl";
import ModalSkeleton from "../ModalSkeleton";
import { useSelector, useDispatch } from "react-redux";
import classes from "./AddEditClientModal.module.css";
import { TextArea } from "../../Component/TextArea";
import UploadImageBox from "../../Component/UploadImageBox";
import { DropDown } from "../../Component/DropDown/DropDown";
const AddEditClientModal = ({ show, setShow, data, onClick }) => {
  const inputStyle = {
    borderRadius: "10px",
    boxShadow: "21px 14px 24px #00000012",
    border: "1px solid #E5E5E5",
  };
  const { access_token } = useSelector((state) => state.authReducer);
  const [image, setImage] = useState(null);
  const [price, setPrice] = useState("");
  const [loadingCapacity, setLoadingCapacity] = useState("");
  const [modelYear, setModelYear] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);
  async function handler() {
    let params = {
      photo: image,
      name: name,
      description: description,
    };
    console.log("ppppppp", params);
    for (let key in params) {
      if (params[key] == "" || params[key] == null) {
        return toast.error(`Please fill the ${key} field`);
      }
    }
    params.isActive = isActive?.name == "Active" ? true : false;
    const formData = await CreateFormData(params);
    setIsLoading(true);
    await onClick(formData);
    setIsLoading(false);
  }

  useEffect(() => {
    if (data) {
      setImage(data?.photo);
      setName(data?.name);
      setDescription(data?.description);
      setIsActive({ name: data?.isActive == true ? "Active" : "De-active" });
    }
  }, [data]);
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={`${data ? "Edit" : "Add"} Client`}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row md={12}>
        <UploadImageBox
          state={image}
          setter={setImage}
          label="Image"
          onEdit={(e) => setImage(e)}
          isMultiple={false}

        />
        <Col md={12}>
          <Input
            label="Name"
            placeholder="Name"
            setter={setName}
            value={name}
            customStyle={inputStyle}
          />
        </Col>

        <Col md={12}>
          <TextArea
            placeholder="Enter Description"
            label="Description"
            value={description}
            setter={setDescription}
            form
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12}>
          <DropDown
            labelClassName={classes.labelText}
            label={"Status"}
            placeholder={"Status"}
            disabled={false}
            isMulti={false}
            options={[
              {
                name: "Active",
              },
              {
                name: "De-active",
              },
            ]}
            value={isActive}
            setter={setIsActive}
            optionLabel={"name"}
            optionValue={"name"}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12} className={classes.jCenter}>
          <Button
            variant="secondary"
            className={classes.loginBtn}
            onClick={handler}
            disabled={isLoading}
          >
            {isLoading ? "Wait..." : data ? "Edit" : "Add"}
          </Button>
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default AddEditClientModal;
