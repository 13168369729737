import React from "react";
import classes from "./LabelAndValue.module.css";

export default function LabelAndValue({ label, value, className = "" }) {
  return (
    <div className={[classes?.labelAndValue, className].join(" ")}>
      <label>{label}</label>
      <p>{value}</p>
    </div>
  );
}
