import "./assets/Styles/style.css";
import "./assets/Styles/table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-modern-drawer/dist/index.css";
import "font-awesome/css/font-awesome.min.css";
import "react-quill/dist/quill.snow.css";
import "react-clock/dist/Clock.css";
import React, { Suspense, lazy, useEffect, useRef } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ProtectedRouter from "./Helper/ProtectedRoute";
import { Loader } from "./Component/Loader";
import MyProfile from "./pages/MyProfile";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { apiUrl, BaseURL } from "./config/apiUrl";
import { Beforeunload } from "react-beforeunload";
import Notifications from "./pages/Notifications";
import {
  saveChatCounter,
  saveNotiData,
  setAllPackage,
} from "./store/common/commonSlice";
import moment from "moment";
import { Get } from "./Axios/AxiosFunctions";
import { updateUser } from "./store/auth/authSlice";
import TypeCrud from "./pages/TypeCrud";
import VehicleCrud from "./pages/VehicleCrud";
import ClientCrud from "./pages/ClientCrud";
import PartnerCrud from "./pages/PartnerCrud";
import Packages from "./pages/Packages";
import Driver from "./pages/Driver";
import BeforeLoginRoute from "./Helper/BeforeLoginRoute";
import Customer from "./pages/Customer";
import RequestedDrivers from "./pages/RequestedDrivers";
import RequestedPartners from "./pages/RequestedPartners";
import DriverDetails from "./pages/DriverDetails";
import WithdrawalRequests from "./pages/WithdrawalRequests";
import CreatePartnerRide from "./pages/CreatePartnerRide/CreatePartnerRide";
import PartnerDetails from "./pages/PartnerDetails";
import CustomerDetails from "./pages/CustomerDetails";
import RideDetail from "./pages/RideDetail/RideDetail";

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Transactions = lazy(() => import("./pages/Transactions"));
const AllUsers = lazy(() => import("./pages/AllUsers"));
const NotFound = lazy(() => import("./pages/NotFound"));

export let socket = null;
function App() {
  const {
    user,
    isLogin,
    access_token: accessToken,
  } = useSelector((state) => state?.authReducer);
  const userDataState = useSelector((state) => state?.authReducer?.user);
  const dispatch = useDispatch();

  const getPackageData = async () => {
    const url = BaseURL(`package`);
    const response = await Get(url, accessToken);
    if (response) {
      dispatch(setAllPackage(response?.data));
      console.log(response);
    }
  };

  const fetchNotificationsForCount = async () => {
    const url = BaseURL("notifications/all?page=1&limit=5&noCountUpdate=true");
    let response = await Get(url, accessToken);

    if (response !== undefined) {
      response?.data?.newNotifications > 0 &&
        dispatch(saveNotiData(response?.data?.newNotifications));
    }
  };

  const getMe = async () => {
    const url = BaseURL("users/me");
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      const apiUser = response?.data?.data?.user;
      dispatch(updateUser(apiUser));
    }
  };

  useEffect(() => {
    if (isLogin) {
      socket = io(apiUrl, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionDelay: 180000,
        reconnectionDelayMax: 300000,
      });
      socket?.emit("join", userDataState?._id);
      // fetchNotificationsForCount();
      // getMe();
      getPackageData();
    }
  }, [isLogin]);

  useEffect(() => {
    socket?.on("new-notification", (notification, number) => {
      //something went wrong here
      if (
        notification?.receiver == userDataState?._id &&
        window.location.pathname !== "/notifications" &&
        notification?.flag == "project"
      ) {
        dispatch(saveNotiData());
      }
      //something went wrong here

      if (
        notification?.receiver == userDataState?._id &&
        notification?.flag == "chat" &&
        window.location.pathname !== "/chat"
      ) {
        dispatch(saveChatCounter(notification));
        dispatch(saveNotiData());
      }
    });
    return () => {
      socket?.off("new-notification");
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <Beforeunload
        onBeforeunload={() => {
          if (isLogin) {
            socket?.emit("disconnected", user?._id);
          }
        }}
      />
      <BrowserRouter>
        <Suspense fallback={<Loader className={"mvh-100"} />}>
          <Routes>
            <Route
              path="/"
              exact
              element={<BeforeLoginRoute element={<Login />} />}
            />

            <Route
              path="/dashboard"
              exact
              element={<ProtectedRouter element={<Dashboard />} />}
            />

            {/* <Route
              path="/transactions"
              exact
              element={<ProtectedRouter element={<Transactions />} />}
            /> */}
            <Route
              path="/all-users"
              exact
              element={<ProtectedRouter element={<AllUsers />} />}
            />

            {/* <Route
              path="/type-crud"
              exact
              element={<ProtectedRouter element={<TypeCrud />} />}
            /> */}
            {/* User Routes */}
            <Route
              path="/drivers"
              exact
              element={<ProtectedRouter element={<Driver />} />}
            />
            <Route
              path="/driver-detail/:id"
              exact
              element={<ProtectedRouter element={<DriverDetails />} />}
            />
            <Route
              path="/partners"
              exact
              element={<ProtectedRouter element={<PartnerCrud />} />}
            />
            <Route
              path="/partner-detail/:id"
              exact
              element={<ProtectedRouter element={<PartnerDetails />} />}
            />
            <Route
              path="/customers"
              exact
              element={<ProtectedRouter element={<Customer />} />}
            />
            <Route
              path="/customer-detail/:id"
              exact
              element={<ProtectedRouter element={<CustomerDetails />} />}
            />
            {/* User Routes */}

            {/* Requests */}
            <Route
              path="/requested-drivers"
              exact
              element={<ProtectedRouter element={<RequestedDrivers />} />}
            />
            <Route
              path="/requested-partners"
              exact
              element={<ProtectedRouter element={<RequestedPartners />} />}
            />
            {/* Requests */}

            <Route
              path="/client-crud"
              exact
              element={<ProtectedRouter element={<ClientCrud />} />}
            />

            {/* <Route
              path="/vehicle-crud"
              exact
              element={<ProtectedRouter element={<VehicleCrud />} />}
            /> */}
            <Route
              path="/packages"
              exact
              element={<ProtectedRouter element={<Packages />} />}
            />
            <Route
              path="/account-setting"
              exact
              element={<ProtectedRouter element={<MyProfile />} />}
            />

            {/* <Route
              path="/notifications"
              exact
              element={<ProtectedRouter element={<Notifications />} />}
            /> */}
            <Route
              path="/withdrawal-requests"
              exact
              element={<ProtectedRouter element={<WithdrawalRequests />} />}
            />
            <Route
              path="/create-partner-ride/:partnerId"
              exact
              element={<ProtectedRouter element={<CreatePartnerRide />} />}
            />
            <Route
              path="/ride/:rideId"
              exact
              element={<ProtectedRouter element={<RideDetail />} />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
