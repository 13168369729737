import React, { useEffect, useState } from "react";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./Customer.module.css";
import { useSelector } from "react-redux";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { BaseURL, apiHeader, recordsLimit } from "../../config/apiUrl";
import PaginationComponent from "../../Component/PaginationComponent";
import NoData from "../../Component/NoData/NoData";
import TableSkeleton from "../../Component/TableSkeleton";
import { Button } from "../../Component/Button/Button";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { toast } from "react-toastify";
import SearchInput from "../../Component/SearchInput";
import { DropDown } from "../../Component/DropDown/DropDown";
import useDebounce from "../../CustomHooks/useDebounce";
import { HiLockClosed, HiLockOpen } from "react-icons/hi";
import { AiFillEye } from "react-icons/ai";

const Customer = () => {
  const { access_token: accessToken } = useSelector(
    (state) => state?.authReducer
  );
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [isModalLoading, setIsModalLoading] = useState("");
  const [status, setStatus] = useState(statusoptions[0]);
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500);

  async function getData(
    pg = page,
    sts = status?.value,
    searchText = debounceSearch
  ) {
    const url = BaseURL(
      `users/admin/all?page=${pg}&limit=${recordsLimit}&userType=customer&search=${searchText}&status=${sts}`
    );
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalRecords);
    }
    setLoading(false);
  }

  useEffect(() => {
    setPage(1);
    getData(1);
  }, [debounceSearch]);

  const handleStatusUpdate = async () => {
    setIsModalLoading("status-update");
    const url = BaseURL(`users/active-or-inactive/${selectedItem?._id}`);
    const response = await Patch(
      url,
      {
        active: selectedItem?.active == "active" ? false : true,
      },
      apiHeader(accessToken)
    );
    setIsModalLoading("");
    if (response) {
      console.log("res", response?.data?.data);
      const newData = [...data];
      const index = newData.findIndex((item) => item._id == selectedItem._id);
      if (response?.data?.data?.active == status?.value) {
        newData.splice(index, 1, response?.data?.data);
      } else {
        newData.splice(index, 1);
      }
      setData(newData);
      setShow("");
      toast.success(
        `Customer ${
          response?.data?.data?.active == "active" ? "Activated" : "Deactivated"
        } successfully`
      );
    }
  };

  return (
    <SideBarSkeleton>
      <div className={classes.content}>
        <Container className={classes.main}>
          <Row className={classes.row}>
            <Col md={12}>
              <div className={classes.spBtw}>
                <h3>Customers</h3>
                <div className={classes?.searchAndFilters}>
                  <SearchInput setter={setSearch} value={search} />
                  <DropDown
                    customStyle={{ minWidth: "200px", padding: "2px 0px" }}
                    placeholder={"Status"}
                    variant="dashboard"
                    indicatorColor="var(--white-color)"
                    options={statusoptions}
                    value={status}
                    setter={(e) => {
                      setStatus(e);
                      setPage(1);
                      getData(1, e?.value);
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div class="table100 ver1 m-b-110">
                <div class="table100-head">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th
                          class="cell100 column1"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          S.No
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Name
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Email
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Phone
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Address
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Created At
                        </th>
                        <th
                          class="cell100 column4"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Status
                        </th>

                        <th class="cell100 column5" style={{ width: "15%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {loading ? (
                  <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                ) : (
                  <div class="table100-body js-pscroll ps ps--active-y">
                    <table>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((item, index) => (
                            <tr class="row100 body">
                              <td
                                class="cell100 column1"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "10%",
                                  textAlign: "left",
                                }}
                              >
                                <div className={classes.descCol}>
                                  <p className="textOneLine">
                                    {item?.displayName}
                                  </p>
                                </div>
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "start",
                                }}
                              >
                                <p className="textOneLine">{item?.email}</p>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <p className="textOneLine">{item?.contact}</p>
                              </td>
                              <td
                                class="cell100 column4"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                <p className="textOneLine">{item?.address}</p>
                              </td>
                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {moment(item?.createdAt).format(
                                    "DD MMM YYYY hh:mm"
                                  )}
                                </div>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {item?.active == "system-deactivated"
                                  ? "Deactive"
                                  : "Active"}
                              </td>
                              <td
                                class="cell100 column5"
                                style={{ width: "15%" }}
                              >
                                <div className={classes.actions_btn}>
                                  <Button
                                    className={classes.viewDetailBtn}
                                    leftIcon={
                                      item?.active == "system-deactivated" ? (
                                        <HiLockOpen size={20} />
                                      ) : (
                                        <HiLockClosed size={20} />
                                      )
                                    }
                                    onClick={() => {
                                      setSelectedItem(item);
                                      setShow("status-update");
                                      //   navigate(`/project-detail/${item?._id}`);
                                    }}
                                    title={
                                      item?.active == "system-deactivated"
                                        ? "Activate"
                                        : "Deactivate"
                                    }
                                  />
                                  <Button
                                    className={classes.viewDetailBtn}
                                    leftIcon={<AiFillEye size={20} />}
                                    title={"View"}
                                    onClick={() => {
                                      setSelectedItem(item);
                                      setShow("View");
                                        navigate(`/customer-detail/${item?._id}`);
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoData text={"No Customers Found"} />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Col>

            <Col md={12}>
              <div className={classes.paginationDiv}>
                {!!data?.length && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={(e) => {
                      setPage(e);
                      getData(e);
                    }}
                    currentPage={page}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {show === "status-update" && (
        <AreYouSureModal
          show={show}
          setShow={setShow}
          data={selectedItem}
          subTitle={`Are you sure you want to ${
            selectedItem?.active == "system-deactivated"
              ? "Activate"
              : "Deactivate"
          } this Customer?`}
          onClick={handleStatusUpdate}
          isApiCall={isModalLoading == "status-update"}
        />
      )}
    </SideBarSkeleton>
  );
};

export default Customer;

const statusoptions = [
  {
    value: "active",
    label: "Active",
  },
  {
    label: "Deactive",
    value: "system-deactivated",
  },
];
