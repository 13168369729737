import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Loader } from "../../Component/Loader";
import SideBarSkeleton from "../../Component/SideBarSkeleton";

import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import { BaseURL } from "../../config/apiUrl";
import classes from "./RideDetail.module.css";
import { IoLocationSharp } from "react-icons/io5";

const RideDetail = () => {
  const rideId = useParams()?.rideId;
  const { access_token: accessToken } = useSelector(
    (state) => state?.authReducer
  );
  const [loading, setLoading] = useState("initial");
  const [rideData, setRideData] = useState(null);

  // get ride detail
  const getRideDetail = async () => {
    const url = BaseURL(`partner-scheduling-ride/detail/${rideId}`);
    const response = await Get(url, accessToken);

    setLoading("");

    if (response) {
      const res = response?.data?.data;

      const allRoutes = res?.rides?.map((item) => {
        return {
          address: item?.dropOffAddress,
          lat: item?.dropOffLocation?.coordinates[1],
          lng: item?.dropOffLocation?.coordinates[0],
        };
      });

      //   add item to start of array
      allRoutes.unshift({
        address: res?.pickUpAddress,
        lat: res?.pickUpLocation?.coordinates[1],
        lng: res?.pickUpLocation?.coordinates[0],
      });
      setRideData({ ...res, allRoutes });
    }
  };

  //   side effects
  useEffect(() => {
    getRideDetail();
  }, []);

  console.log("rideData ", rideData);

  return (
    <SideBarSkeleton>
      {loading == "initial" ? (
        <Loader className={classes?.loader} />
      ) : (
        <Container className={classes.main}>
          {/* Header */}
          <Row className={"mt-5 "}>
            <div className={`${classes.rideCardWrap} ${classes.account_head}`}>
              <h3>Ride Detail</h3>
            </div>
          </Row>

          {/* Ride Detail */}
          {rideData?.allRoutes?.map((r, i) => {
            return (
              <div key={i} className={classes?.rideMapper}>
                <h5>{i === 0 ? "Pickup" : "Dropoff"} Location</h5>
                <div className={classes?.rideMapperItem}>
                  <IoLocationSharp />
                  <span>{r?.address}</span>
                </div>
              </div>
            );
          })}
        </Container>
      )}
    </SideBarSkeleton>
  );
};

export default RideDetail;
