import {
  falsyArray,
  getFormattedParams,
  validateEmail,
} from "../config/apiUrl";

export const validateRoutes = (routes) => {
  for (let i = 0; i < routes.length; i++) {
    for (let key in routes[i]) {
      if (falsyArray?.includes(routes[i][key])) {
        if (
          ["dropOffLocationLongitude", "dropOffLocationLatitude"].includes(key)
        ) {
          return [
            true,
            `Please select valid drop-off location in route ${i + 1}.`,
          ];
        }

        return [
          true,
          `Please fill ${getFormattedParams(key)} field in route ${i + 1} `,
        ];
      }

      if (key === "customerEmail") {
        if (!validateEmail(routes[i][key])) {
          return [true, `Please enter valid email in route ${i + 1}.`];
        }
      }
    }
  }

  return [false, ""];
};

export const validateCreateRide = (params) => {
  for (let key in params) {
    if (key === "images") {
      if (params[key]?.length === 0) {
        return [true, "Please upload images."];
      }
    }

    if (["latitude", "longitude", "pickUpAddress"]?.includes(key)) {
      if (falsyArray?.includes(params[key])) {
        return [true, `Please enter valid pickup location.`];
      }
    }

    if (key === "scheduledDate") {
      // check if date is valid && greater than current date [24 hours ahead]
      if (new Date(params[key]) < new Date(Date.now() + 24 * 60 * 60 * 1000)) {
        return [true, `Scheduled date should be atleast 24 hours ahead.`];
      }
    }

    if (key === "driver" && !params[key]) {
      return [true, `Please select a driver for the ride.`];
    }

    if (falsyArray?.includes(params[key])) {
      return [true, `Please enter/select valid ${getFormattedParams(key)}.`];
    }
  }

  return [false, ""];
};
