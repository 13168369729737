import { DirectionsService, GoogleMap, Marker } from "@react-google-maps/api";
import React from "react";
import classes from "./MapView.module.css";

export default function MapView({ location, className }) {
  const [response, setResponse] = React.useState(null);
  const allDestination = [
    { coordinates: { lat: 40.712776, lng: -74.005974 } }, // New York
    { coordinates: { lat: 34.052235, lng: -118.243683 } }, // Los Angeles
  ];

  let count = React.useRef(0);

  const directionsCallback = React.useCallback((res) => {
    console.log(count.current);
    if (res !== null) {
      if (res.status === "OK" && count.current < 2) {
        count.current += 1;
        setResponse(res);
      } else {
        count.current = 0;
        console.log("res: ", res);
      }
    }
  }, []);

  return (
    <div className={`${classes?.container} ${className && className}`}>
      <GoogleMap
        zoom={16}
        center={allDestination?.at(-1)?.coordinates}
        // options={options}
        mapContainerClassName={classes["map-container"]}
      >
        {/* {location && (
          <>
            <Marker position={location} />
          </>
        )} */}

        <DirectionsService
          options={{
            waypoints: [
              ...allDestination?.map((item) => ({
                location: item?.coordinates,
              })),
            ],
          }}
          callback={directionsCallback}
        />
      </GoogleMap>
    </div>
  );
}
