import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SideBarSkeleton from "../../Component/SideBarSkeleton";

import { AiFillCloseCircle } from "react-icons/ai";
import { GiCardboardBoxClosed, GiWeightScale } from "react-icons/gi";
import { MdEmail, MdLocationOn } from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { DropDown } from "../../Component/DropDown/DropDown";
import { Input } from "../../Component/Input/Input";
import Maps from "../../Component/MapAndPlaces";
import { TextArea } from "../../Component/TextArea";
import UploadImageBox from "../../Component/UploadImageBox";
import { validateCreateRide, validateRoutes } from "../../Helper/commonHelper";
import { weightArray } from "../../config/DummyData";
import {
  BaseURL,
  CreateFormData,
  apiHeader,
  getFormattedParams,
} from "../../config/apiUrl";
import classes from "./CreatePartnerRide.module.css";

const CreatePartnerRide = () => {
  const partnerId = useParams()?.partnerId;
  const { access_token: accessToken } = useSelector(
    (state) => state?.authReducer
  );

  //   states
  const [image, setImage] = useState([]);
  const [coordinates, setCoordinates] = useState(null);
  const [address, setAddress] = useState("");
  const [weight, setWeight] = useState("");
  const [dateAndTime, setDateAndTime] = useState("");
  const [routes, setRoutes] = useState([defaultRouteObj]);
  const [orderDetails, setOrderDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [availableDrivers, setAvailableDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");

  // get available drivers
  const getAvailableDrivers = async () => {
    const url = BaseURL("users/get-available-drivers");

    const res = await Get(url, accessToken);
    if (res) {
      setAvailableDrivers(res?.data?.data);

      // if we have selected driver then check if it is still available
      if (selectedDriver) {
        const isAvailable = res?.data?.data?.find(
          (item) => item?._id === selectedDriver?._id
        );
        if (!isAvailable) {
          setSelectedDriver("");
        }
      }
    }
  };

  //   handle add route
  const handleAddRoute = () => {
    // validate the previous route
    const [isError, message] = validateRoutes(routes);
    if (isError) {
      return toast.warn(message);
    }

    const temp = [...routes];
    temp.push(defaultRouteObj);
    setRoutes(temp);
  };

  // handle send request
  const handleSendRequest = async () => {
    const params = {
      images: image,
      // pick-up location [latitude,longitude,  address]
      pickUpAddress: address,
      latitude: coordinates?.lat,
      longitude: coordinates?.lng,

      coordinates,
      weight,
      scheduledDate: dateAndTime,
      orderDetails,
      driver: selectedDriver?._id,
      locations: routes,
      partner: partnerId,
    };

    // validate params
    const [isErrorInParams, messageInParams] = validateCreateRide(params);
    if (isErrorInParams) {
      return toast.warn(messageInParams);
    }

    // validate routes
    const [isErrorInRoutes, messageInRoutes] = validateRoutes(routes);
    if (isErrorInRoutes) {
      return toast.warn(messageInRoutes);
    }

    //  create form data
    const formData = CreateFormData(params);

    // API
    const url = BaseURL(`partner-scheduling-ride/confirm/locations`);
    setIsLoading(true);
    const res = await Post(url, formData, apiHeader(accessToken, true));
    setIsLoading(false);

    if (res) {
      // ! to do
    }
  };

  // side effects
  useEffect(() => {
    getAvailableDrivers();

    const intervalId = setInterval(() => {
      getAvailableDrivers();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <SideBarSkeleton>
        <Container className={classes.main}>
          {/* Header */}
          <Row className={"mt-5 "}>
            <div className={`${classes.rideCardWrap} ${classes.account_head}`}>
              <h3>Partner Detail</h3>
            </div>
          </Row>

          {/* Content */}
          <Row className={classes?.gap}>
            {/* Images */}
            <Col md={12}>
              <div className={classes.label}>
                <MdLocationOn size={25} color="var(--primary-color)" />
                <p>Upload Images (upto 10)</p>
              </div>
              <UploadImageBox
                colCount={4}
                setter={setImage}
                state={image}
                maxLength={10}
              />
            </Col>

            <Col md={12}>
              {/* Loc */}
              <Col md={12}>
                <div className={classes.label}>
                  <MdLocationOn size={25} color="var(--primary-color)" />
                  <p>Pick-Up Location</p>
                </div>

                <Maps
                  type="location"
                  setCoordinates={(coordinates) => {
                    setCoordinates(coordinates);
                  }}
                  placeholder="Enter Your Location"
                  placeClass={classes?.locationAutoComplete}
                  setAddress={setAddress}
                  address={address}
                />
              </Col>

              {/* Weight */}
              <Col md={12}>
                <div className={classes.label}>
                  <GiWeightScale size={25} color="var(--primary-color)" />
                  <p>Weight</p>
                </div>
                <DropDown
                  options={weightArray}
                  value={weight}
                  setter={setWeight}
                  placeholder={"Weight Here"}
                />
              </Col>

              {/* Date and Time */}
              <Col md={12}>
                <div className={classes.label}>
                  <GiWeightScale size={25} color="var(--primary-color)" />
                  <p>Scheduled Date</p>
                </div>
                <Input
                  type={"datetime-local"}
                  value={dateAndTime}
                  setter={setDateAndTime}
                  placeholder={"Date And Time Here"}
                  min={new Date(Date.now() + 24 * 60 * 60 * 1000)
                    .toISOString()
                    .slice(0, -8)} // 24 hours ahead (1 day)
                />
              </Col>

              <Col md={12}>
                <div className={classes.label}>
                  <GiCardboardBoxClosed
                    size={25}
                    color="var(--primary-color)"
                  />
                  <p>Order Details</p>
                </div>
                <TextArea
                  value={orderDetails}
                  setter={setOrderDetails}
                  rows={5}
                  placeholder={"Type...."}
                />
              </Col>

              <Col md={12}>
                <div className={classes.label}>
                  <GiWeightScale size={25} color="var(--primary-color)" />
                  <p>Select Driver</p>
                </div>

                <DropDown
                  value={selectedDriver}
                  setter={setSelectedDriver}
                  placeholder={"Select Driver"}
                  getOptionLabel={(option) =>
                    `${getFormattedParams(option?.displayName)} - ${
                      option?.email
                    }`
                  }
                  optionValue={"_id"}
                  options={availableDrivers}
                />
              </Col>

              {/* Add Routes */}
              <div>
                <Col md={12} className={classes.routeDetail}>
                  <h6>Route Details</h6>
                  {routes?.length < 5 && (
                    <Button
                      label={"Add Route"}
                      onClick={handleAddRoute}
                      className={classes.btn}
                    />
                  )}
                </Col>

                {/* Routes Mapper */}
                {routes?.map((item, index) => (
                  <Col
                    md={12}
                    key={index}
                    className={classes?.singleRouteContainer}
                  >
                    <Row>
                      <Col md={12} className={classes.routeNo}>
                        <p>Route {index + 1}</p>
                        {routes?.length > 1 && (
                          <AiFillCloseCircle
                            className={classes.crossIcon}
                            size={25}
                            color="var(--primary-color)"
                            onClick={() => {
                              setRoutes(routes.filter((_, i) => index !== i));
                            }}
                          />
                        )}
                      </Col>
                      <Col md={12}>
                        <div className={classes.label}>
                          <MdLocationOn
                            size={25}
                            color="var(--primary-color)"
                          />
                          <p>Drop-Off Location</p>
                        </div>

                        <Maps
                          type="location"
                          setCoordinates={(e) => {
                            console.log("set coordinates", e);
                            const prevData = [...routes];
                            prevData[index].dropOffLocationLatitude =
                              e?.lat || "";
                            prevData[index].dropOffLocationLongitude =
                              e?.lng || "";
                            prevData[index].dropOffAddress = e?.val || "";
                            setRoutes(prevData);
                          }}
                          placeholder="Enter Your Location"
                          placeClass={classes?.locationAutoComplete}
                          setAddress={(e) => {
                            console.log("set address", e);
                            const prevData = [...routes];
                            prevData[index].dropOffAddress = e;
                            setRoutes(prevData);
                          }}
                          address={item?.dropOffAddress}
                        />
                      </Col>
                      <Col md={12}>
                        <div className={classes.label}>
                          <MdEmail size={25} color="var(--primary-color)" />
                          <p>Customer Email</p>
                        </div>
                        <Input
                          placeholder={"Email Here"}
                          value={item?.customerEmail}
                          setter={(e) => {
                            const prevData = [...routes];
                            prevData[index].customerEmail = e;
                            setRoutes(prevData);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                ))}
              </div>

              <Col md={12}>
                <Button
                  disabled={isLoading}
                  label={!isLoading ? "Confirm" : "Wait..."}
                  className={`${classes.btn} ${classes.confirmBtn}`}
                  onClick={handleSendRequest}
                />
              </Col>
            </Col>
          </Row>
        </Container>
      </SideBarSkeleton>
    </>
  );
};

export default CreatePartnerRide;

const defaultRouteObj = {
  customerEmail: "",
  dropOffAddress: "",
  dropOffLocationLongitude: "",
  dropOffLocationLatitude: "",
};
