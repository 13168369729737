import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import { toast } from "react-toastify";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import classes from "./MyProfile.module.css";
import { FaEdit } from "react-icons/fa";
import UpdatePasswordModal from "../../modals/UpdatePasswordModal";
import { Patch } from "../../Axios/AxiosFunctions";
import { useSelector, useDispatch } from "react-redux";
import { apiHeader, BaseURL, CreateFormData } from "../../config/apiUrl";
import { updateUser } from "../../store/auth/authSlice";
import { ProfileWithEditButton } from "../../Component/ProfileWithEditButton";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import validator from "validator";
const MyProfile = () => {
  const dispatch = useDispatch();
  const { user, access_token: accessToken } = useSelector(
    (state) => state.authReducer
  );

  console.log({user})
  const inputStyle = {
    borderRadius: "10px",
    boxShadow: "-4px 4px 30px #0000001F",
  };
  const [name, setName] = useState(user?.displayName);
  const [phoneNo, setPhoneNo] = useState(user?.contact);
  const [email, setEmail] = useState(user?.email);
  const [profileImg, setProfileImg] = useState(user?.photo ? user?.photo : "");

  // Contact Info
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");

  const [showModal, setshowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleEditProfile = async () => {
    const params = {
      photo: profileImg,
      displayName:name,
      contact: phoneNo,
      contactEmail,
      contactPhone,
    };

    // validate
    for (let key in params)
      if (!params[key]) return toast.error(`Fields can not be empty.`);
    // call api
    if(!validator.isMobilePhone(phoneNo)) return toast.error(`Please enter valid phone number.`)
    if(!validator.isEmail(contactEmail)) return toast.error(`Please enter valid contact email.`)
    if(!validator.isMobilePhone(contactPhone)) return toast.error(`Please enter valid contact phone number.`)
    const formData = await CreateFormData(params);
    const url = BaseURL(`users/updateMe`);

    setIsLoading(true);
    const response = await Patch(url, formData, apiHeader(accessToken, true));
    setIsLoading(false);
    if (response != undefined) {
      dispatch(updateUser(response?.data?.user));
      toast.success("Your Profile Upadted Succesfully");
    }
  };
  return (
    <SideBarSkeleton>
      <Container fluid className={classes.conatiner}>
        <Row className={classes.main}>
          <Col md={12}>
            <div className={classes.head}>
              <h3>Account Setting</h3>
              <Button
                label={"Update Password"}
                className={classes.editPasswordBtn}
                rightIcon={<FaEdit size={20} />}
                onClick={() => setshowModal(true)}
              />
            </div>
          </Col>
          <Col md={12}>
            {/* <Row>
              <Col md={3}> */}
            {/* <div className={classes.profileImg}>
                  <img src={profile} alt="" />
                </div> */}
            <ProfileWithEditButton
              updateImage={profileImg}
              setUpdateImage={setProfileImg}
              isEdit={true}
            />
            {/* </Col>
            </Row> */}
          </Col>
          <Col md={12}>
            <Row className={classes.innerRowGap}>
              <Col  xl={8} lg={10} md={12}>
                <Input
                  setter={setName}
                  value={name}
                  placeholder={"Type Your Name"}
                  label={"Name"}
                  type={"text"}
                  customStyle={inputStyle}
                  inputStyle={{
                    paddingBlock: "16px",
                  }}
                />
              </Col>
              {/* <Col md={6} xl={4} lg={5}>
                <Input
                  setter={setLastName}
                  value={lastName}
                  placeholder={"Type Your Last Name"}
                  label={"Last Name"}
                  type={"text"}
                  customStyle={inputStyle}
                  inputStyle={{
                    paddingBlock: "16px",
                  }}
                />
              </Col> */}
            </Row>
          </Col>
          <Col md={12}>
            <Row className={classes.innerRowGap}>
              <Col md={6} xl={4} lg={5}>
                <Input
                  setter={setEmail}
                  value={email}
                  placeholder={"Enter Email "}
                  label={"Email"}
                  type={"email"}
                  customStyle={inputStyle}
                  disabled={true}
                  inputStyle={{
                    paddingBlock: "16px",
                  }}
                />
              </Col>
              <Col md={6} xl={4} lg={5}>
                <CustomPhoneInput
                  setter={setPhoneNo}
                  value={phoneNo}
                  placeholder={"Enter your phone number"}
                  label={"Phone Number"}
                  containerStyle={{ ...inputStyle, paddingBlock: "0px" }}
                />
              </Col>
            </Row>
          </Col>

          {/* Contact Info */}
          <Col md={12}>
            <Row className={classes.innerRowGap}>
              <Col md={12}>
                <h4>Contact Info</h4>
              </Col>
              <Col md={6} xl={4} lg={5}>
                <Input
                  setter={setContactEmail}
                  value={contactEmail}
                  placeholder={"Enter your contact Email"}
                  label={"Email"}
                  type={"email"}
                  customStyle={inputStyle}
                  inputStyle={{
                    paddingBlock: "16px",
                  }}
                />
              </Col>
              <Col md={6} xl={4} lg={5}>
                <CustomPhoneInput
                  setter={setContactPhone}
                  value={contactPhone}
                  placeholder={"Enter your contact phone number"}
                  label={"Phone Number"}
                  containerStyle={{ ...inputStyle, paddingBlock: "0px" }}
                />
              </Col>
            </Row>
          </Col>

          <Col md={12} xl={4} lg={5} sm={12}>
            <Button
              label={isLoading ? "Saving..." : "Save"}
              className={classes.editBtn}
              onClick={handleEditProfile}
            />
          </Col>
        </Row>
      </Container>
      <UpdatePasswordModal show={showModal} setShow={setshowModal} />
    </SideBarSkeleton>
  );
};

export default MyProfile;
