import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddEditPartnerModal.module.css";

import "react-phone-input-2/lib/style.css";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import { DropDown } from "../../Component/DropDown/DropDown";
import Maps from "../../Component/MapAndPlaces";
import { toast } from "react-toastify";
import {
  falsyArray,
  getFormattedParams,
  isValidPhoneNumber,
  validateEmail,
} from "../../config/apiUrl";

const AddEditPartnerModal = ({
  show,
  setShow,
  data,
  onClick,
  isLoading,
  requestMode = false,
}) => {
  const { allPackage } = useSelector((state) => state?.commonReducer);

  //create states for these fields photo , email , contact , country , active
  const [params, setParams] = useState({
    displayName: "" || data?.displayName,
    email: "" || data?.email,
    contact: "" || data?.contact,
    dialCode: "" || data?.dialCode,
    companyName: "" || data?.companyName,
    address: "" || data?.address,
    latitude: "" || data?.currentLocation?.coordinates[1],
    longitude: "" || data?.currentLocation?.coordinates[0],
    packageId: "" || data?.packageDetails?.packageId,
  });

  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"60vw"}
      header={
        requestMode ? "Partner Request" : `${data ? "Edit" : "Add"} Partner`
      }
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row md={12} className={classes.row_main}>
        <Col md={12}>
          <Input
            label="Name"
            placeholder="Name"
            type="text"
            value={params.displayName}
            onChange={(e) =>
              setParams({ ...params, displayName: e.target.value })
            }
          />
        </Col>

        <Col md={12}>
          <Input
            label="Email"
            placeholder="Email"
            type="email"
            value={params.email}
            onChange={(e) => setParams({ ...params, email: e.target.value })}
          />
        </Col>
        <Col md={12}>
          <CustomPhoneInput
            label="Contact"
            placeholder="Contact"
            value={`${params.dialCode}${params.contact}`}
            setter={(e, code) => {
              setParams({
                ...params,
                contact: e.slice(code?.dialCode.length),
                dialCode: code.dialCode,
              });
            }}
          />
        </Col>
        <Col md={12}>
          <Input
            label="Company Name"
            placeholder="Company Name"
            type="text"
            value={params.companyName}
            onChange={(e) =>
              setParams({ ...params, companyName: e.target.value })
            }
          />
        </Col>
        <Col md={12}>
          <DropDown
            label={"Package"}
            placeholder={"Package"}
            value={params.packageId}
            options={allPackage}
            optionLabel={"name"}
            optionValue={"_id"}
            setter={(e) => setParams({ ...params, packageId: e })}
          />
        </Col>
        <Col md={12}>
          <Maps
            setCoordinates={(e) => {
              setParams({
                ...params,
                latitude: e.lat,
                longitude: e.lng,
                address: e.val,
              });
            }}
            type="places"
            label="Address"
            placeholder="Address"
            address={params.address}
          />
        </Col>

        <Button
          variant="secondary"
          className={classes.loginBtn}
          onClick={() => {
            const finalParams = {
              displayName: params.displayName,
              email: params.email,
              contact: params.contact,
              dialCode: params.dialCode,
              companyName: params.companyName,
              address: params.address,
              latitude: String(params.latitude),
              longitude: String(params.longitude),
              packageId: params.packageId?._id,
            };

            // validate
            for (let key in finalParams) {
              if (falsyArray.includes(finalParams[key])) {
                return toast.warn(
                  `Please fill ${getFormattedParams(key)} field.`
                );
              }
            }

            // validate email
            if (!validateEmail(finalParams.email)) {
              return toast.warn("Please enter a valid email.");
            }

            // validate contact
            if (!isValidPhoneNumber(finalParams.contact)) {
              return toast.warn("Please enter a valid contact number.");
            }

            // validate lat long
            if (!finalParams.latitude || !finalParams.longitude) {
              return toast.warn("Please select a valid address.");
            }

            onClick(finalParams);
          }}
          disabled={isLoading}
        >
          {isLoading
            ? "Wait..."
            : requestMode
            ? "Accept Request"
            : data
            ? "Edit"
            : "Add"}
        </Button>
      </Row>
    </ModalSkeleton>
  );
};

export default AddEditPartnerModal;
