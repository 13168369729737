import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdModeEditOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import TableSkeleton from "../../Component/TableSkeleton";
import {
  BaseURL,
  apiHeader,
  getFormattedPrice,
  recordsLimit,
} from "../../config/apiUrl";
import AddEditPackageModal from "../../modals/AddEditPackageModal";
import classes from "./Packages.module.css";

const Packages = () => {
  const { access_token: accessToken } = useSelector(
    (state) => state?.authReducer
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  // get data function
  async function getData(pg = page) {
    const url = BaseURL(`package/?page=${pg}&limit=${recordsLimit}`);
    setLoading(true);
    const response = await Get(url, accessToken);
    if (response) {
      setData(response?.data?.data);
      setTotalResults(response?.data?.totalRecords);
    }
    setLoading(false);
  }

  // addOrEditData function
  const addOrEditData = async (e) => {
    const url = BaseURL(`package/${selectedItem?._id}`);
    const response = await Patch(url, e, apiHeader(accessToken));
    if (response !== undefined) {
      const newData = [...data];
      newData?.splice(
        newData?.findIndex((item) => item?._id == response?.data?._id),
        1,
        response?.data
      );
      setData(newData);
      toast.success(`Package edited successfully`);
    }
    setShow("");
  };

  // side effects
  useEffect(() => {
    getData(page);
  }, [page]);

  return (
    <SideBarSkeleton>
      <div className={classes.content}>
        <Container className={classes.main}>
          <Row className={classes.row}>
            <Col md={12}>
              <div className={classes.spBtw}>
                <h3>Packages</h3>
              </div>
            </Col>
            <Col md={12}>
              <div class="table100 ver1 m-b-110">
                <div class="table100-head">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th
                          class="cell100 column1"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          S.No
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Name
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Price
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Package Type
                        </th>

                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          Last Updated
                        </th>
                        <th
                          class="cell100 column4"
                          style={{ width: "15%", textAlign: "center" }}
                        >
                          Sorting Order
                        </th>

                        <th class="cell100 column5" style={{ width: "10%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {loading ? (
                  <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                ) : (
                  <div class="table100-body js-pscroll ps ps--active-y">
                    <table>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map((item, index) => (
                            <tr class="row100 body">
                              <td
                                class="cell100 column1"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "left",
                                }}
                              >
                                <div
                                  className={`${classes.descCol} t-t-c maxLine1`}
                                >
                                  {item?.name}
                                </div>
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "start",
                                }}
                              >
                                {getFormattedPrice(item?.price)}
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {item?.packageType}
                                </div>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "center" }}
                              >
                                <div className={classes.descCol}>
                                  {moment(item?.updatedAt).format("lll")}
                                </div>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{
                                  width: "15%",
                                  textAlign: "center",
                                }}
                              >
                                {item?.order}
                              </td>

                              <td
                                class="cell100 column5"
                                style={{ width: "10%" }}
                              >
                                <div className={classes.actions_btn}>
                                  <Button
                                    className={classes.viewDetailBtn}
                                    title={"Edit"}
                                    leftIcon={<MdModeEditOutline size={20} />}
                                    onClick={() => {
                                      setSelectedItem(item);
                                      setShow("viewEditAdd");
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoData text={"No Package Found"} />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Col>
            <Col md={12}>
              <div className={classes.paginationDiv}>
                {!!data?.length && (
                  <PaginationComponent
                    totalPages={Math.ceil(totalResults / recordsLimit)}
                    setCurrentPage={setPage}
                    currentPage={page}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {show === "viewEditAdd" && (
        <AddEditPackageModal
          show={show}
          setShow={setShow}
          data={selectedItem}
          onClick={addOrEditData}
        />
      )}
    </SideBarSkeleton>
  );
};

export default Packages;
