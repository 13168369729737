import React from "react";
import { BiSearch } from "react-icons/bi";
import { Input } from "../Input/Input";

function SearchInput({
  value,
  setter,
  placeholder = "Search",
  customStyle = {
    height: "50px",
    borderRadius: "8px",
    width: "280px",
    padding: "0px",
    boxShadow: '-4px 4px 30px #0000001F'
  },
  inputStyle = {
    padding: "8px 14px",
    fontSize: "14px",
    paddingLeft: "50px",
  },
}) {
  return (
    <Input
      setter={setter}
      value={value}
      customStyle={customStyle}
      inputStyle={inputStyle}
      placeholder={placeholder}
      leftIcon={<BiSearch size={22} color={"var(--placeholder-color)"} />}
    />
  );
}

export default SearchInput;
