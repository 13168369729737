import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddEditPackageModal.module.css";

import { AiFillCloseCircle } from "react-icons/ai";
import AddMoreBtn from "../../Component/AddMoreBtn";
import { getFormattedParams } from "../../config/apiUrl";
const AddEditPackageModal = ({ show, setShow, data, onClick }) => {
  const inputStyle = {
    borderRadius: "10px",
    boxShadow: "21px 14px 24px #00000012",
    border: "none",
  };

  //create states for these fields name, order, packageType, price, scheduleRides,
  const [name, setName] = useState("");
  const [order, setOrder] = useState("");
  const [packageType, setPackageType] = useState("");
  const [price, setPrice] = useState("");
  const [scheduleRides, setScheduleRides] = useState("");
  const [descText, setDescText] = useState("");
  const [description, setDescription] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // create a function to handle the submit button
  async function handler() {
    let params = {
      name: name,
      order: order,
      packageType: packageType,
      price: price,
      scheduleRides: scheduleRides,
    };

    // validate the fields
    for (let key in params) {
      if (params[key] == "" || params[key] == null) {
        return toast.error(`Please fill the ${key} field`);
      }
    }

    params.description = description;

    // price validation
    if (isNaN(price)) {
      return toast.error("Price must be a number");
    }

    // order validation
    if (isNaN(order)) {
      return toast.error("Order must be a number");
    }

    // scheduleRides validation
    if (isNaN(scheduleRides)) {
      return toast.error("Schedule Rides must be a number");
    }

    // description validation
    if (description.length == 0) {
      return toast.error("Please add some description");
    }

    setIsLoading(true);
    await onClick(params);
    setIsLoading(false);
  }

  useEffect(() => {
    if (data) {
      setName(data.name);
      setOrder(data.order);
      setPackageType(data.packageType);
      setPrice(data.price);
      setScheduleRides(data.scheduleRides);
      setDescription(data.description);
    }
  }, [data]);
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={`Edit Package`}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={classes.row}>
        <Col md={12}>
          <Input
            placeholder={"Enter Package Name"}
            label={"Package Name"}
            value={name}
            setter={setName}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={6}>
          <Input
            placeholder={"Enter Price"}
            label={"Price"}
            value={price}
            setter={setPrice}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={6}>
          <Input
            placeholder={"Enter Order"}
            label={"Sorting Order"}
            value={order}
            setter={setOrder}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={6}>
          <Input
            placeholder={"Enter Package Type"}
            label={"Package Type"}
            disabled={true}
            value={getFormattedParams(packageType)}
            setter={setPackageType}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={6}>
          <Input
            placeholder={"Enter Plan Type"}
            label={"Schedule Rides"}
            value={scheduleRides}
            setter={setScheduleRides}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12}>
          <Input
            placeholder={"Enter Description"}
            label={
              <div className={classes.labelDiv}>
                <span>{"Description"}</span>
                <AddMoreBtn
                  onClick={() => {
                    descText && setDescription([...description, descText]);
                    setDescText("");
                  }}
                />
              </div>
            }
            value={descText}
            setter={setDescText}
            customStyle={inputStyle}
            onEnterClick={() => {
              setDescription([...description, descText]);
              setDescText("");
            }}
          />
        </Col>
        <Col md={12} className={classes.listContainer}>
          <ul>
            {description?.map((item, key) => (
              <li key={key}>
                <span>{item}</span>
                <AiFillCloseCircle
                  onClick={() => {
                    let arr = [...description];
                    arr.splice(key, 1);
                    setDescription(arr);
                  }}
                  color={"var(--primary-color)"}
                  size={20}
                />
              </li>
            ))}
          </ul>
        </Col>
        <Col md={12}>
          <Button
            variant="secondary"
            className={classes.loginBtn}
            onClick={handler}
            disabled={isLoading}
          >
            {isLoading ? "Wait..." : "Edit"}
          </Button>
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default AddEditPackageModal;
