/* eslint-disable */

import React, { useRef } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { UploadImageValidtor, imageUrl } from "../../config/apiUrl";
import classes from "./UploadImageBox.module.css";
import { Button } from "../Button/Button";
import { toast } from "react-toastify";
import { CgImage } from "react-icons/cg";

import { Col, Row } from "react-bootstrap";

function UploadImageBox({
  state,
  setter,
  edit = true,
  isMultiple = true,
  maxLength = 6,
  placeholder = "",
  className = "",
  colCount = 12,
  videoAndImage = false,
  type,
}) {
  const inputRef = useRef(null);

  // HandleUploadFile
  const HandleUploadFile = (e) => {
    if (isMultiple) {
      // max images should be 6
      if (parseInt(e.target.files.length) + state.length > maxLength) {
        return toast.warn(`you can not select more than ${maxLength} images.`);
      }
      let notImage = false;
      Array.from(e.target.files)?.map((file) => {
        if (!UploadImageValidtor(file, videoAndImage)) {
          return (notImage = true);
        }
      });
      if (notImage) {
        return toast.error("Invalid Image");
      }
      setter((pre) => [...pre, ...Array.from(e.target.files)]);
    } else {
      let file = e.target.files[0];
      if (!UploadImageValidtor(file, videoAndImage)) {
        return toast.error("Invalid Image");
      }
      setter(file);
    }
  };
  const HandleDeleteFile = (index) => {
    if (isMultiple) {
      setter((prev) => prev.filter((_, i) => i !== index));
    } else {
      setter(null);
    }
  };

  return (
    <div className={[classes.box, className].join(" ")}>
      {((isMultiple && state?.length < maxLength) || state == null) && (
        <div
          className={[classes.uploadBox, classes.flexCol].join(" ")}
          onClick={() => inputRef.current.click()}
        >
          <CgImage className={classes.uploadIcon} />
          {placeholder && (
            <span className={classes.placeholder}>{placeholder}</span>
          )}
        </div>
      )}
      <Row className="gy-2">
        {Array.isArray(state)
          ? state.map((file, index) => {
              return (
                <Col md={colCount}>
                  <div className={classes.uploadBox}>
                    {file?.type?.startsWith("video/") ? (
                      <video
                        src={
                          typeof file == "object"
                            ? URL.createObjectURL(file)
                            : imageUrl(file)
                        }
                        width="100%"
                        height="100%"
                        controls
                      />
                    ) : (
                      <img
                        style={{
                          objectFit: type == "cover" ? "cover" : "contain",
                        }}
                        src={
                          typeof file == "object"
                            ? URL.createObjectURL(file)
                            : imageUrl(file)
                        }
                      />
                    )}
                    {edit && (
                      <div className={classes.editAndDelete}>
                        <Button
                          className={classes.icon}
                          onClick={() => HandleDeleteFile(index)}
                          leftIcon={<RiDeleteBinLine />}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              );
            })
          : state && (
              <div className={classes.uploadBox}>
                <img
                  style={{
                    objectFit: type == "cover" ? "cover" : "contain",
                  }}
                  src={
                    typeof state == "object"
                      ? URL.createObjectURL(state)
                      : imageUrl(state)
                  }
                />
                {edit && (
                  <div className={classes.editAndDelete}>
                    <Button
                      className={classes.icon}
                      onClick={() => HandleDeleteFile(isMultiple && index)}
                      leftIcon={<RiDeleteBinLine />}
                    />
                  </div>
                )}
              </div>
            )}
      </Row>

      {/* Input For File Upload */}
      <input
        hidden
        type="file"
        ref={inputRef}
        onChange={HandleUploadFile}
        multiple={isMultiple}
        accept={videoAndImage ? ["image/*", "video/*"] : ["image/*"]}
        max={isMultiple ? 6 : 1}
        onClick={(e) => (e.target.value = null)}
      />
    </div>
  );
}

export default UploadImageBox;
