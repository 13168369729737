import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Patch, Post } from '../../Axios/AxiosFunctions';
import { Button } from '../../Component/Button/Button';
import { Input } from '../../Component/Input/Input';
import {
  apiHeader,
  BaseURL,
  CreateFormData,
  imageUrl,
  validateEmail,
} from '../../config/apiUrl';
import ModalSkeleton from '../ModalSkeleton';
import { useSelector, useDispatch } from 'react-redux';
import classes from './AddEditVehicleModal.module.css';

import UploadImageBox from '../../Component/UploadImageBox';
import { DropDown } from '../../Component/DropDown/DropDown';
const AddEditVehicleModal = ({ show, setShow, data, onClick }) => {
  const inputStyle = {
    borderRadius: '46px',
    boxShadow: '21px 14px 24px #00000012',
  };
  const { access_token } = useSelector((state) => state.authReducer);
  //create states for these fields image, price, loadingCapacity, modelYear, name, type, trunkSpace,
  const [image, setImage] = useState(null);
  const [price, setPrice] = useState('');
  const [loadingCapacity, setLoadingCapacity] = useState('');
  const [modelYear, setModelYear] = useState('');
  const [name, setName] = useState('');
  const [vehicalType, setVehicalType] = useState('');
  const [trunkSpace, setTrunkSpace] = useState('');
  const [isActive, setIsActive] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);
  async function handler() {
    let params = {
      image: image,
      price: price,
      loadingCapacity: loadingCapacity,
      modelYear: modelYear,
      name: name,
      type: vehicalType.name,
      trunkSpace: trunkSpace,
    };
    console.log('ppppppp', params);
    for (let key in params) {
      if (params[key] == '' || params[key] == null) {
        return toast.error(`Please fill the ${key} field`);
      }
    }
    params.isActive = isActive?.name == 'Active' ? true : false;
    const formData = await CreateFormData(params);
    setIsLoading(true);
    await onClick(formData);
    setIsLoading(false);
  }
  const vehicalTypeArray = [
    { name: '2-wheeler' },
    { name: '4-wheeler' },
    { name: '6-wheeler' },
    { name: '8-wheeler' },
    { name: '10-wheeler' },
    { name: '12-wheeler' },
    { name: '14-wheeler' },
    { name: '14+  -wheeler' },
  ];
  useEffect(() => {
    if (data) {
      setImage(data.image);
      setPrice(data.price);
      setLoadingCapacity(data.loadingCapacity);
      setModelYear(data.modelYear);
      setName(data.name);
      setVehicalType({ name: data.type });
      setTrunkSpace(data.trunkSpace);
      setIsActive({ name: data.isActive == true ? 'Active' : 'De-active' });
    }
  }, [data]);
  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={'600px'}
      header={`${data ? 'Edit' : 'Add'} Vehicle`}
      borderRadius={'20px'}
      showCloseIcon
    >
      <Row md={12}>
        <UploadImageBox
          state={image}
          setter={setImage}
          label='Image'
          onEdit={(e) => setImage(e)}
          isMultiple={false}

        />
        <Col
          md={6}
          className='mt-3'
        >
          <Input
            label='Name'
            placeholder='Name'
            setter={setName}
            value={name}
            style={inputStyle}
          />
        </Col>
        <Col
          md={6}
          className='mt-3'
        >
          <Input
            label='Loading Capacity'
            placeholder='Loading Capacity'
            value={loadingCapacity}
            setter={setLoadingCapacity}
            style={inputStyle}
          />
        </Col>
        <Col
          md={6}
          className='mt-3'
        >
          <Input
            label='Model Year'
            placeholder='Model Year'
            value={modelYear}
            setter={setModelYear}
            style={inputStyle}
          />
        </Col>
        <Col
          md={6}
          className='mt-3'
        >
          <Input
            label='Price'
            placeholder='Price'
            value={price}
            setter={setPrice}
            style={inputStyle}
          />
        </Col>
        <Col
          md={6}
          className='mt-3'
        >
          <Input
            label='Trunk Space'
            placeholder='Trunk Space'
            value={trunkSpace}
            setter={setTrunkSpace}
            style={inputStyle}
          />
        </Col>
        <Col
          md={6}
          className='mt-3'
        >
          <DropDown
            labelClassName={classes.labelText}
            label={'Type'}
            placeholder={'Type'}
            disabled={false}
            isMulti={false}
            options={vehicalTypeArray}
            value={vehicalType}
            setter={setVehicalType}
            optionLabel={'name'}
            optionValue={'name'}
            customStyle={{
              borderRadius: '46px',
              boxShadow: '21px 14px 24px #00000012',
              backgroundColor: 'white',
            }}
          />
        </Col>
        <Col
          md={6}
          className='mt-3'
        >
          <DropDown
            labelClassName={classes.labelText}
            label={'Status'}
            placeholder={'Status'}
            disabled={false}
            isMulti={false}
            options={[
              {
                name: 'Active',
              },
              {
                name: 'De-active',
              },
            ]}
            value={isActive}
            setter={setIsActive}
            optionLabel={'name'}
            optionValue={'name'}
            customStyle={{
              borderRadius: '46px',
              boxShadow: '21px 14px 24px #00000012',
              backgroundColor: 'white',
            }}
          />
        </Col>
        <Col md={12}>
          <Button
            variant='secondary'
            className={classes.loginBtn}
            onClick={handler}
            disabled={isLoading}
          >
            {isLoading ? 'Wait...' : data ? 'Edit' : 'Add'}
          </Button>
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default AddEditVehicleModal;
