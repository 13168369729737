import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Patch, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { Input } from "../../Component/Input/Input";
import { apiHeader, BaseURL, validateEmail } from "../../config/apiUrl";
import ModalSkeleton from "../ModalSkeleton";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ViewDriverDetailModal.module.css";
import UploadImageBox from "../../Component/UploadImageBox";
import LabelAndValue from "../../Component/LabelAndValue";
const ViewDriverDetailModal = ({ show, setShow, data, setData }) => {
  const inputStyle = {
    borderRadius: "46px",
    boxShadow: "21px 14px 24px #00000012",
  };
  const { access_token } = useSelector((state) => state.authReducer);
  //create states for these fields image, price, loadingCapacity, modelYear, name, type, trunkSpace,
  const [image, setImage] = useState(null);

  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"700px"}
      header={"View Driver Details"}
      borderRadius={"20px"}
      showCloseIcon
    >
      <Row className={styles?.row}>
        <Col md={12}>
          <UploadImageBox state={image} setter={setImage} label="Image"
            isMultiple={false}
            />
        </Col>
        <Col md={6}>
          <LabelAndValue value={data?.displayName} label={"Name"} />
        </Col>
        <Col md={6}>
          <LabelAndValue value={data?.email} label={"Email"} />
        </Col>
        <Col md={6}>
          <LabelAndValue value={data?.contact} label={"Phone"} />
        </Col>

        <Col md={6}>
          <LabelAndValue value={data?.country} label={"Country"} />
        </Col>
        <Col md={6}>
          <LabelAndValue value={data?.state} label={"Province"} />
        </Col>
        <Col md={6}>
          <LabelAndValue value={data?.city} label={"City"} />
        </Col>
        <Col md={12}>
          <LabelAndValue value={data?.address} label={"Address"} />
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default ViewDriverDetailModal;
