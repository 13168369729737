const SideBarMenu = [
  {
    name: "Dashboard",
    path: "/dashboard",
  },
  {
    name: "Type Crud",
    path: "/type-crud",
  },
  {
    name: "Vehicle Crud",
    path: "/vehicle-crud",
  },
  {
    name: "Client Crud",
    path: "/client-crud",
  },
  {
    name: "Partner Crud",
    path: "/partner-crud",
  },
  {
    name: "Packages",
    path: "/packages",
  },
  {
    name: "Transactions",
    path: "/transactions",
  },
  {
    name: "All Users",
    path: "/all-users",
  },
  {
    name: "Awaiting Acceptance",
    path: "/awaiting-acceptance",
  },
  {
    name: "New Tasks",
    path: "/new-tasks",
  },
  {
    name: "Archive",
    path: "/archive",
  },
  {
    name: "Packages",
    path: "/packages",
  },
  {
    name: "Chat",
    path: "/chat",
  },
  {
    name: "Withdrawal Requests",
    path: "/withdrawal-requests",
  },
  {
    name: "Notifications",
    path: "/notifications",
  },
];

const listingArr = [
  {
    name: "Lorem ipsum",
    price: "20000",
    sales: "20000",
    cashFlow: "20000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem ipsum",
    price: "20000",
    sales: "20000",
    cashFlow: "20000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem ipsum",
    price: "20000",
    sales: "20000",
    cashFlow: "20000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem ipsum",
    price: "20000",
    sales: "20000",
    cashFlow: "20000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem ipsum",
    price: "20000",
    sales: "20000",
    cashFlow: "20000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem ipsum",
    price: "20000",
    sales: "20000",
    cashFlow: "20000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem ipsum",
    price: "20000",
    sales: "20000",
    cashFlow: "20000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem ipsum",
    price: "20000",
    sales: "20000",
    cashFlow: "20000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem ipsum",
    price: "20000",
    sales: "20000",
    cashFlow: "20000",
    city: "New York",
    status: "Signed NDA",
  },
];

const ndaCard = [
  {
    title: "Total Listings",
    value: "123,456",
    percentageValue: "3.48",
  },
  {
    title: "Total Listings",
    value: "-123,456",
    percentageValue: "3.48",
  },
  {
    title: "Total Listings",
    value: "123,456",
    percentageValue: "3.48",
  },
];

const inquiedListingArr = [
  {
    name: "Lorem Ipsum",
    price: "2000",
    sales: "2000",
    cashFlow: "2000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem Ipsum",
    price: "2000",
    sales: "2000",
    cashFlow: "2000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem Ipsum",
    price: "2000",
    sales: "2000",
    cashFlow: "2000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem Ipsum",
    price: "2000",
    sales: "2000",
    cashFlow: "2000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem Ipsum",
    price: "2000",
    sales: "2000",
    cashFlow: "2000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem Ipsum",
    price: "2000",
    sales: "2000",
    cashFlow: "2000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem Ipsum",
    price: "2000",
    sales: "2000",
    cashFlow: "2000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem Ipsum",
    price: "2000",
    sales: "2000",
    cashFlow: "2000",
    city: "New York",
    status: "Signed NDA",
  },
  {
    name: "Lorem Ipsum",
    price: "2000",
    sales: "2000",
    cashFlow: "2000",
    city: "New York",
    status: "Signed NDA",
  },
];

export const weightArray = [
  {
    label: "Single Unit, up to 100 Pounds: $30",
    price: 30,
    weight: 100,
    unit: "Single Unit",
    _id: 1,
  },
  {
    label: "Multi Units, up to 200 pounds: $50",
    price: 50,
    weight: 200,
    unit: "Multi Units",
    _id: 2,
  },
  {
    label: "Single Unit, up to 200 Pounds: $50",
    price: 50,
    weight: 200,
    unit: "Single Unit",
    _id: 3,
  },
  {
    label: "Single Unit, up to 300 Pounds: $80",
    price: 80,
    weight: 300,
    unit: "Single Unit",
    _id: 4,
  },
  {
    label: "Double Units, up to 400 Pounds: $90",
    price: 90,
    weight: 400,
    unit: "Double Units",
    _id: 5,
  },
  {
    label: "Multi Units, up to 600 Pounds: $120",
    price: 120,
    weight: 600,
    unit: "Multi Units",
    _id: 5,
  },
  // {
  //   label: "Bulk Units, Contact Admin",
  //   price: null,
  //   weight: null,
  //   unit: "Bulk Units",
  //   _id: 7,
  // },
];

export { SideBarMenu, listingArr, ndaCard, inquiedListingArr };
