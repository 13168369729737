import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import { Loader } from "../../Component/Loader";
import NoData from "../../Component/NoData/NoData";
import PaginationComponent from "../../Component/PaginationComponent";
import RideCard from "../../Component/RideCard";
import SideBarSkeleton from "../../Component/SideBarSkeleton";
import TableSkeleton from "../../Component/TableSkeleton";
import {
  BaseURL,
  apiHeader,
  recordsLimit,
  recordsLimitSM,
} from "../../config/apiUrl";
import classes from "./PartnerDetails.module.css";

const PartnerDetails = () => {
  const navigate = useNavigate();
  // partner id
  const id = useParams()?.id;
  const [loading, setLoading] = useState("initial");
  const [apiData, setApiData] = useState(null);
  const { access_token: accessToken } = useSelector(
    (state) => state?.authReducer
  );
  const [widthdrawData, setWidthdrawData] = useState([]);
  const [partnerRides, setPartnerRides] = useState([]);
  const [partnerRidesPage, setPartnerRidesPage] = useState(1);
  const [partnerRidesTotalRecords, setPartnerRidesTotalRecords] = useState(0);

  const statusButtonText = `${
    apiData?.active == "active"
      ? loading == "active-deactive"
        ? "Deactivating..."
        : "Deactivate Account"
      : loading == "active-deactive"
      ? "Activating..."
      : "Activate Account"
  }`;

  //  get data
  const getData = async () => {
    setLoading("initial");
    const [response, response1] = await Promise.allSettled([
      Get(BaseURL(`users/detail/${id}`), accessToken),
      Get(BaseURL(`transactions/admin?id=${id}`), accessToken),
    ]);
    setLoading("");

    if (response?.status == "fulfilled") {
      setApiData(response?.value?.data?.data?.user);
    }
    if (response1?.status == "fulfilled") {
      setWidthdrawData(response1?.value?.data?.data);
    }
  };

  // activeDeactivateApi
  const activeDeactivateApi = async () => {
    const params = {
      active: apiData?.active == "active" ? false : true,
    };
    setLoading("active-deactive");
    const url = BaseURL(`users/active-or-inactive/${apiData?._id}`);
    const response = await Patch(url, params, apiHeader(accessToken));
    setLoading("");

    if (response) {
      setApiData(response?.data?.data);
      toast.success(
        `Partner ${
          response?.data?.data?.active == "active" ? "Activated" : "Deactivated"
        } successfully`
      );
    }
  };

  // get Partner Rides
  const getPartnerRides = async (_page = partnerRidesPage) => {
    const url = BaseURL(
      `partner-scheduling-ride/admin/partner/rides/${id}?page=${_page}&limit=${recordsLimitSM}`
    );

    loading !== "initial" && setLoading("partner-rides");
    const response = await Get(url, accessToken);
    if (response) {
      setPartnerRides(response?.data?.data);
      setPartnerRidesTotalRecords(response?.data?.totalRecords);
    }

    loading !== "initial" && setLoading("");
  };

  // side effect
  useEffect(() => {
    getData();
    getPartnerRides();
  }, []);

  return (
    <>
      <SideBarSkeleton>
        {loading == "initial" ? (
          <Loader className={classes?.loader} />
        ) : (
          <Container className={classes.main}>
            <Row className={"mt-5 "}>
              <div
                className={`${classes.rideCardWrap} ${classes.account_head}`}
              >
                <h3>Partner Detail</h3>

                <Button
                  label={"Add New Ride"}
                  variant="primary"
                  onClick={() => {
                    navigate(`/create-partner-ride/${id}`);
                  }}
                />
              </div>

              <Col md={6}>
                <div className={classes.box_main}>
                  <Row>
                    <Col md={6} className={classes.left_box}>
                      <div className={classes.circle}>
                        <h5>01</h5>
                      </div>
                      <div>
                        <p>Total Schedule Rides</p>
                      </div>
                    </Col>
                    <Col md={6} className={classes.small_cricles}>
                      <div>
                        <div className={classes.circle}>
                          <h5>01</h5>
                        </div>
                        <div>
                          <p>Pending Rides</p>
                        </div>
                      </div>
                      <div>
                        <div className={classes.circle}>
                          <h5>01</h5>
                        </div>
                        <div>
                          <p>Completed Rides</p>
                        </div>
                      </div>
                      <div>
                        <div className={classes.circle}>
                          <h5>01</h5>
                        </div>
                        <div>
                          <p>Canceled Rides</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            {/* Profile & Intro */}
            <Row className={"mt-5 "}>
              <div
                className={`${classes.rideCardWrap} ${classes.account_head}`}
              >
                <h3>Account Detail</h3>

                <Button
                  label={statusButtonText}
                  variant="primary"
                  onClick={activeDeactivateApi}
                  disabled={loading == "active-deactive"}
                />
              </div>
              <div className={classes.account_details}>
                {/* b/c partner do not have a profile photo */}
                {/* <div className={classes.account_pic_wrapper}>
                  <img
                    src={
                      "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80"
                    }
                  />
                </div> */}
                <div className={classes.account_desc}>
                  <b>Name:</b>
                  <p className="t-t-c maxLine1" title={apiData?.displayName}>
                    {apiData?.displayName}
                  </p>
                </div>
                <div className={classes.account_desc}>
                  <b>Email:</b>
                  <p className="maxLine1" title={apiData?.email}>
                    {apiData?.email}
                  </p>
                </div>
                <div className={classes.account_desc}>
                  <b>Phone Number:</b>
                  <p>{`${apiData?.dialCode}${apiData?.contact}`}</p>
                </div>
                <div className={classes.account_desc}>
                  <b>Company Name:</b>
                  <p
                    className="t-t-c maxLine1"
                    title={apiData?.companyName}
                  >{`${apiData?.companyName}`}</p>
                </div>
                <div className={classes.account_desc}>
                  <b>Company Address:</b>
                  <p className="wbbw">{`${apiData?.address}`}</p>
                </div>

                <div className={classes.account_desc}>
                  <b>Package:</b>
                  <p className="t-t-c">{`${
                    apiData?.packageDetails?.packageType ?? "None"
                  }`}</p>
                </div>
              </div>
            </Row>

            {/* Routes with pagination */}
            <Row className={"mt-5 "}>
              {loading === "partner-rides" && (
                <Loader className={classes?.loaderSM} />
              )}

              {partnerRides?.length > 0 && loading !== "partner-rides" && (
                <div className={classes.rideCardWrap}>
                  <h3>Routes</h3>
                  <div className={classes.routes_box}>
                    {partnerRides?.map((item, index) => (
                      <RideCard
                        item={item}
                        key={index}
                        onClick={() => navigate(`/ride/${item?._id}`)}
                      />
                    ))}
                  </div>
                </div>
              )}

              {loading !== "partner-rides" && partnerRides?.length === 0 && (
                <NoData
                  text={"No Routes Found"}
                  show={partnerRides?.length == 0}
                />
              )}

              <Col md={12}>
                <div className={classes.paginationDiv}>
                  {partnerRidesTotalRecords > recordsLimit && (
                    <PaginationComponent
                      totalPages={Math.ceil(
                        partnerRidesTotalRecords / recordsLimit
                      )}
                      setCurrentPage={(e) => {
                        if (e == partnerRidesPage) return;
                        setPartnerRidesPage(e);
                        getPartnerRides(e);
                      }}
                      currentPage={partnerRidesPage}
                    />
                  )}
                </div>
              </Col>
            </Row>

            {/* Withdrawal Requests with pagination */}
            <Row className={"mt-5 "}>
              <div className={classes.rideCardWrap}>
                <h3>Withdrawal Request</h3>
              </div>
              <div class="table100 ver1 m-b-110">
                <div class="table100-head">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th
                          class="cell100 column1"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          S.No
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Name
                        </th>
                        <th
                          class="cell100 column2"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Email
                        </th>
                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Phone
                        </th>

                        <th
                          class="cell100 column3"
                          style={{ width: "15%", textAlign: "start" }}
                        >
                          Created At
                        </th>
                        <th
                          class="cell100 column4"
                          style={{ width: "10%", textAlign: "start" }}
                        >
                          Status
                        </th>

                        <th class="cell100 column5" style={{ width: "25%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                {loading ? (
                  <TableSkeleton rowsCount={recordsLimit} colsCount={7} />
                ) : (
                  <div class="table100-body js-pscroll ps ps--active-y">
                    <table>
                      <tbody>
                        {widthdrawData?.length > 0 ? (
                          widthdrawData?.map((item, index) => (
                            <tr class="row100 body">
                              <td
                                class="cell100 column1"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "10%",
                                  textAlign: "left",
                                }}
                              >
                                <div className={classes.descCol}>
                                  <p className="textOneLine">
                                    {item?.displayName}
                                  </p>
                                </div>
                              </td>
                              <td
                                class="cell100 column2"
                                style={{
                                  width: "15%",
                                  textAlign: "start",
                                }}
                              >
                                <p className="textOneLine">{item?.email}</p>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <p className="textOneLine">{item?.contact}</p>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "15%", textAlign: "left" }}
                              >
                                <div className={classes.descCol}>
                                  {moment(item?.createdAt).format(
                                    "DD MMM YYYY hh:mm"
                                  )}
                                </div>
                              </td>

                              <td
                                class="cell100 column4"
                                style={{ width: "10%", textAlign: "left" }}
                              >
                                {item?.active == "system-deactivated"
                                  ? "Deactive"
                                  : "Active"}
                              </td>
                              <td
                                class="cell100 column5"
                                style={{ width: "25%" }}
                              >
                                <div className={classes.actions_btn}>
                                  <Button
                                    className={classes.viewDetailBtn}
                                    label={
                                      item?.active == "system-deactivated"
                                        ? "Activate"
                                        : "Deactivate"
                                    }
                                    onClick={() => {
                                      // setSelectedItem(item);
                                      // setShow("status-update");
                                      //   navigate(`/project-detail/${item?._id}`);
                                    }}
                                  />
                                  <Button
                                    className={classes.viewDetailBtn}
                                    label={"Edit"}
                                    onClick={() => {
                                      // setSelectedItem({ ...item, index });
                                      // setShow("add-edit");
                                      //   navigate(`/project-detail/${item?._id}`);
                                    }}
                                  />
                                  <Button
                                    className={classes.viewDetailBtn}
                                    label={"View"}
                                    onClick={() => {
                                      // setSelectedItem(item);
                                      // setShow("View");
                                      //   navigate(`/project-detail/${item?._id}`);
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoData text={"No Withdraw Requests Found"} />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </Row>
          </Container>
        )}
      </SideBarSkeleton>
    </>
  );
};

export default PartnerDetails;
