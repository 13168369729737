import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Patch, Post } from "../../Axios/AxiosFunctions";
import { Button } from "../../Component/Button/Button";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import { Input } from "../../Component/Input/Input";
import Maps from "../../Component/MapAndPlaces";
import UploadImageBox from "../../Component/UploadImageBox";
import {
  BaseURL,
  apiHeader,
  isValidPhoneNumber,
  validateEmail,
} from "../../config/apiUrl";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddEditDriverModal.module.css";
import { ProfileWithEditButton } from "../../Component/ProfileWithEditButton";

const AddEditDriverModal = ({
  show,
  setShow,
  data,
  onClick,
  setData,
  requestMode = false,
}) => {
  const inputStyle = {
    borderRadius: "10px",
    boxShadow: "-4px 4px 30px #0000001F",
    border: "none",
  };
  const { access_token } = useSelector((state) => state.authReducer);
  const [photo, setPhoto] = useState("");
  const [numberPlatePhoto, setNumberPlatePhoto] = useState(null);
  const [licensePhoto, setLicensePhoto] = useState(null);
  const [vehiclePhoto, setVehiclePhoto] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [contact, setContact] = useState("");
  const [dialCode, setDialCode] = useState("+1");
  const [postalCode, setPostalCode] = useState("");
  const [carColor, setCarColor] = useState("");
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // create a submit function
  const handler = async () => {
    let params = {
      photo: photo,
      displayName: name,
      email: email,
      phone: contact,
      country: country,
      state: state,
      city: city,
      postalCode: postalCode,
      address: address,
      color: carColor,
      dialCode: dialCode,
      contact: contact,
      licenseNumber: licenseNumber,
      numberPlateImage: numberPlatePhoto,
      licenseImage: licensePhoto,
      vehicleImage: vehiclePhoto,
      latitude: coordinates?.lat,
      longitude: coordinates?.lng,
    };

    // validation
    for (let key in params) {
      if (params[key] == "" || params[key] == null) {
        return toast.error(`Please fill the ${key} field`);
      }
    }

    // validate email
    if (!validateEmail(email)) return toast.error("Please enter a valid email");

    // validate contact
    if (!isValidPhoneNumber(contact))
      return toast.error("Please enter a valid contact number");

    // validate address [lat lng]
    if (!params?.latitude || !params?.longitude)
      return toast.error("Please select a valid address");

    // create form data
    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }

    // if requestMode
    if (requestMode) {
      setIsLoading(true);
      await Post(
        BaseURL("auth/admin/create/driver"),
        formData,
        apiHeader(access_token, true)
      );
      toast.success("Driver created successfully");
      onClick();
      setIsLoading(false);
      return;
    }

    let apiFunc = data ? Patch : Post;
    const url = BaseURL(
      data ? `users/admin/update/user/${data?._id}` : "auth/admin/create/driver"
    );

    setIsLoading(true);
    const response = await apiFunc(
      url,
      formData,
      apiHeader(access_token, true)
    );
    setIsLoading(false);

    if (response) {
      setData((prev) => {
        let tempData = [...prev];
        if (data) {
          tempData.splice(data?.index, 1, response?.data?.data);
        } else {
          tempData.unshift(response?.data?.data?.user);
        }
        return tempData;
      });

      toast.success(`Driver ${data ? "updated" : "added"} successfully`);
      setShow(false);
    }
  };

  useEffect(() => {
    if (data) {
      setPhoto(data?.photo || "");
      setName(data?.displayName);
      setEmail(data?.email);
      setCountry(data?.country);
      setState(data?.state);
      setCity(data?.city);
      setPostalCode(data?.postalCode);
      setCarColor(data?.driverInfo?.color);
      setContact(data?.contact);
      setLicenseNumber(data?.driverInfo?.licenseNumber);
      setLicensePhoto(data?.driverInfo?.licenseImage);
      setVehiclePhoto(data?.driverInfo?.vehicleImage);
      setAddress(data?.address);
      setCoordinates({
        lat: data?.currentLocation?.coordinates[1],
        lng: data?.currentLocation?.coordinates[0],
      });
      setNumberPlatePhoto(data?.driverInfo?.numberPlateImage);
    }
  }, [data]);

  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"80vw"}
      header={requestMode ? "Create Driver" : `${data ? "Edit" : "Add"} Driver`}
      borderRadius={"20px"}
      showCloseIcon
      modalClass={classes?.modalBody}
    >
      <Row className={classes.row_main} md={12}>
        <Col md={12}>
          <ProfileWithEditButton
            updateImage={photo}
            setUpdateImage={setPhoto}
            isEdit={true}
          />
        </Col>
        <Col md={6}>
          <Input
            label="Name"
            placeholder="Name"
            setter={setName}
            value={name}
            customStyle={inputStyle}
            autoComplete="password"
          />
        </Col>
        <Col md={6}>
          <Input
            label="Email"
            placeholder="Email"
            setter={setEmail}
            value={email}
            disabled={data}
            customStyle={inputStyle}
            autoComplete="password"
          />
        </Col>
        <Col md={6}>
          <Maps
            type="input"
            address={address}
            setAddress={setAddress}
            setCoordinates={setCoordinates}
            label={"Address"}
            setPlaceDetail={(e) => {
              console.log(e, "place detail");
              setCountry(e.country);
              setState(e.state);
              setCity(e.city);
              e.zipcode && setPostalCode(e.zipcode);
            }}
          />
        </Col>
        <Col md={6}>
          <Input
            label={"Country"}
            setter={(e) => {
              setCountry(e);
            }}
            value={country}
            placeholder={"Enter Country"}
            customStyle={inputStyle}
            autoComplete="password"
          />
        </Col>
        <Col md={6} className={classes.col_main}>
          <Input
            label={"State"}
            setter={(e) => {
              setState(e);
            }}
            value={state}
            placeholder={"Enter state"}
            customStyle={inputStyle}
            autoComplete="password"
          />
        </Col>
        <Col md={6}>
          <Input
            label={"City"}
            setter={(e) => {
              setCity(e);
            }}
            value={city}
            placeholder={"Enter City"}
            customStyle={inputStyle}
            autoComplete="password"
          />
        </Col>
        <Col md={6}>
          <CustomPhoneInput
            label={"Contact Number"}
            value={`${dialCode}${contact}`}
            setter={(e, w) => {
              setDialCode(w.dialCode);
              setContact(e.slice(w.dialCode.length));
            }}
          />
        </Col>
        <Col md={6}>
          <Input
            label="Postal Code"
            placeholder="Postal Code"
            setter={setPostalCode}
            value={postalCode}
            customStyle={inputStyle}
            autoComplete="password"
          />
        </Col>

        <Col md={6}>
          <Input
            label="Car Color"
            placeholder="Car Color"
            setter={setCarColor}
            value={carColor}
            customStyle={inputStyle}
            autoComplete="password"
          />
        </Col>
        <Col md={6}>
          <Input
            label="License Number"
            placeholder="License Number"
            setter={setLicenseNumber}
            value={licenseNumber}
            customStyle={inputStyle}
            autoComplete="password"
          />
        </Col>
        <Col md={12}>
          <UploadImageBox
            state={numberPlatePhoto}
            setter={setNumberPlatePhoto}
            label="Number Plate Photo"
            onEdit={(e) => setNumberPlatePhoto(e)}
            isMultiple={false}
          />
        </Col>

        <Col md={6}>
          <UploadImageBox
            state={licensePhoto}
            setter={setLicensePhoto}
            label="License Photo"
            onEdit={(e) => setLicensePhoto(e)}
            isMultiple={false}

          />
        </Col>
        <Col md={6}>
          <UploadImageBox
            state={vehiclePhoto}
            setter={setVehiclePhoto}
            label="Vehicle Photo"
            onEdit={(e) => setVehiclePhoto(e)}
            isMultiple={false}

          />
        </Col>

        <Col md={12} className={classes.jCenter}>
          <Button
            variant="secondary"
            className={classes.loginBtn}
            onClick={handler}
            disabled={isLoading}
          >
            {isLoading
              ? "Please Wait..."
              : requestMode
              ? "Add Driver"
              : data
              ? "Edit"
              : "Add"}
          </Button>
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default AddEditDriverModal;
